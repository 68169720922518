import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import pagination from './pagination';
import paginationFactory from "react-bootstrap-table2-paginator";
import Spinner from 'react-bootstrap/Spinner';
import { RiAddFill, RiDeleteBin6Line } from "react-icons/ri";
import { FaRegEdit, FaCheck, FaThumbsDown, FaRegTimesCircle, FaCheckCircle, FaTimesCircle, FaMoneyBillAlt, FaToggleOn, FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./table.css";
import Icon from "../../assets/images/traffic-lights.png";
import Dropdown from "react-bootstrap/Dropdown";
import { BsStopwatch } from "react-icons/bs";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Profile from "../../assets/images/Avatar2.png";
import { FiCopy } from "react-icons/fi";
import Accordion from "react-bootstrap/Accordion";
import { getlatestUser } from "../../Api/auth";
import { addUser } from "../../Api/crm";
import { getKeapdataemail } from "../../Api/crm";
import { updateUser } from "../../Api/crm";
import { SITE_URL } from "../../Api/config";
import { getuserbycolumn } from "../../Api/crm";
import { getUserBooking } from "../../Api/crm";
import { deleteUser } from "../../Api/crm";
import Doller from "../../assets/images/icons/currency-dollar.png";
import Pay from "../../assets/images/icons/pay.png";
import Active from "../../assets/images/icons/recycle.png";
import User from "../../assets/images/icons/Avatar.png";
import "../Accordion/Accordion.css";
import "react-notifications/lib/notifications.css";
import Select from "react-select";
import { IoSearch } from "react-icons/io5";
import InputMask from 'react-input-mask';
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import { getEditbookingData, getbookings, getproductlistdataApi, getdefaultpayment } from "../../Api/bookingApi";
import { getactivestaff } from "../../Api/bookingApi";
import { updateBookingData } from "../../Api/bookingApi";
import { updaterecurringbookingAPi } from "../../Api/bookingApi";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import { FormControl } from "react-bootstrap";

import { unconfirmbookingstatus, addbookingbookingstatus, updateUserEmail } from "../../Api/bookingApi";
import { complatebookingstatus } from "../../Api/bookingApi";
import { uncomplatebookingstatus } from "../../Api/bookingApi";
import { activebookingstatus } from "../../Api/bookingApi";
import { confirmbookingstatus } from "../../Api/bookingApi";
import { rejectbookingstatus } from "../../Api/bookingApi";
import { getrescheduledata } from "../../Api/bookingApi";
import { deletebookingApi } from "../../Api/bookingApi";
import { saverescheduledata } from "../../Api/bookingApi";
import { getbookingservicesApi } from "../../Api/bookingApi";
import { updateservicepreferenceApi } from "../../Api/bookingApi";
import { createStaxIdApi } from "../../Api/bookingApi";
import { getinvoiceApi } from "../../Api/bookingApi";
import { getsubserviceaddonsApi, getservicesdataApi } from "../../Api/bookingApi";
import { createinvoiceApi } from "../../Api/bookingApi";
import { BsPlusLg } from "react-icons/bs";
import { getpaymentMethod } from "../../Api/bookingApi";
import { payinvoiceApi } from "../../Api/bookingApi";
import { createPaymentMethod } from "../../Api/bookingApi";
import { getpreferredstaffApi } from "../../Api/bookingApi";
import { usePaymentInputs } from 'react-payment-inputs';
import { reccuringdeletebooking, sendemailpasswordapi } from "../../Api/bookingApi";
import { Roller } from "react-awesome-spinners";
import { DateRange } from 'react-date-range';
import ChildComponent from "../../Bookingdata/Appointment"
import { getusershippingaddress, getusershippingaddressbyid, deleteusershippingaddressApi, addusershippingaddress } from "../../Api/crm";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Typeahead } from 'react-bootstrap-typeahead';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { DateRangePicker } from 'react-date-range';
import { MdAddTask } from "react-icons/md";
import { MdAdd, MdClose } from "react-icons/md";
import { TbEdit } from "react-icons/tb";

const Informasi = (props) => {

  const [cleaningtime, setCleaningTime] = useState();
  const [defaultpaymentmethod, setDefaultpaymentmethod] = useState(null);

  const [customerstage, setCustomerstage] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [profileimage, setProfileimage] = useState(null);
  const [bookingdata, setBookingdata] = useState([]);
  const [delcount, setDelcount] = useState(0);

  const [usershippingaddress, setUsershippingaddress] = useState([]);
  const [Searchkeyword, setSearchkeyword] = useState("");

  const [selectedOption, setSelectedOption] = useState([]);
  const [defaultshippingaddressid, setDefaultshippingaddressid] = useState(null);

  const [show4, setShow4] = useState(false);

  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [loading, setLoading] = useState(false);

  const [default_shipping_address_id, setDefault_shipping_address_id] = useState("");

  const [updateemail, setUpdateemail] = useState('');

  const [edituser, setEdituser] = useState({
    id: "",
    image: null,
    user_email: "",
    user_pwd: "",
    first_name: "",
    last_name: "",
    phone: "",
    zip: "",
    address: "",
    city: "",
    state: "",
    notes: "",
    preferences: "",
    family_info: "",
    parking_access: "",
    preferrend_technician: "",
    special_instructions: "",
    technician_notes: "",
    appointment_note_by_staff: "",
    bathrooms: "",
    bedrooms: "",
    billed_amount: "",
    key_number: "",
    billed_hours: "",
    work_phone: "",
    message_phone: "",
    special_instructions_admin: "",
    payment_method_id: "",
    shipping_address: "",
    shipping_zip: "",
    shipping_city: "",
    shipping_state: "",
    customer_stage: "",
    zone: "",
    client_owner: "",
    email_status: "E",
    sms_status: "E",
    stax_payment_method: "",
    customer_id: "",
    estimated_duration: "",
    estimated_price: "",
    keap_id: "",
    billing_notes: "",
    discount: ""
  });



  const getusershipppingaddress = (id, Searchkeyword) => {
    const formData = new FormData();
    formData.append("user_id", id);
    formData.append("keyword", Searchkeyword);

    //get user data
    getusershippingaddress(formData).then((response) => {
      if (response.code === 200) {
        const apiResponse = response.data;
        setUsershippingaddress(apiResponse.data);
      }
    }).catch((err) => {
      console.log(err);
    }).finally(() => {
      handleShow();
    })
  };

  useEffect(() => {
    getusershipppingaddress(props.id, Searchkeyword);
  }, [Searchkeyword]);

  const [profileImagePreview, setProfileImagePreview] = useState("");

  // const handleEditInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setEdituser((prevUserData) => ({
  //     ...prevUserData,
  //     [name]: value,
  //   }));
  //   console.log("event.target", event.target.value);
  // };
  const handleEditInputChange = (event) => {
    const { name, type, checked } = event.target;
    const value = type === 'checkbox' ? (checked ? "E" : "D") : event.target.value;

    setEdituser((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };


  const handleInputChange55 = (event) => {
    const inputValue = event.target.value;
    const [hours, minutes] = inputValue.split(':');
    const validMinutes = Math.min(Math.max(parseInt(minutes || 0, 10), 0), 59);
    const formattedValue = `${hours || '00'}:${validMinutes.toString().padStart(2, '0')}`;
    let totalDurationvar = convertToMinutes(formattedValue)
    setEdituser((prevUserData) => ({
      ...prevUserData,
      billed_hours: totalDurationvar,
    }))
  };

  const [shippingstate, setshippingstate] = useState("");

  const updatedUser = async (event) => {
    event.preventDefault();
    // const form = event.currentTarget;
    // if (form.checkValidity() === false) {
    //   event.preventDefault();
    //   event.stopPropagation();
    //   setValidated(true);
    // } else {
    const formData = new FormData();
    formData.append("client_id", edituser.id);
    formData.append("first_name", edituser.first_name);
    formData.append("last_name", edituser.last_name);
    formData.append("user_email", edituser.user_email);
    formData.append("phone_number", edituser.phone);
    formData.append("zipcode", edituser.zip);
    formData.append("city", edituser.city);
    formData.append("address", edituser.address);
    formData.append("technician_notes", edituser.technician_notes);
    formData.append("state", state);
    formData.append("preferences", edituser.preferences);
    formData.append("family_info", edituser.family_info);
    formData.append("parking_access", edituser.parking_access);
    formData.append("preferrend_technician", edituser.preferrend_technician);
    formData.append("special_instructions", edituser.special_instructions);
    formData.append(
      "special_instructions_admin",
      edituser.special_instructions_admin
    );
    formData.append("billed_amount", edituser.billed_amount);
    formData.append("billed_hours", edituser.billed_hours);
    formData.append("bathrooms", edituser.bathrooms);
    formData.append("bedrooms", edituser.bedrooms);
    formData.append("zone", edituser.zone);
    formData.append("key_number", edituser.key_number);
    formData.append("customer_stage", edituser.customer_stage);
    formData.append("shipping_address", edituser.shipping_address);
    formData.append("shipping_zip", edituser.shipping_zip);
    formData.append("shipping_state", shippingstate);
    formData.append("shipping_city", edituser.shipping_city);
    formData.append("billing_notes", edituser.billing_notes);
    formData.append("discount", edituser.discount);
    formData.append("sms_status", edituser.sms_status);
    formData.append("email_status", edituser.email_status);
    if (default_shipping_address_id == '') {
      if (selectedOption && selectedOption.length > 0) {
        formData.append("default_shipping_address_id", selectedOption[0].value);
      } else {
        formData.append("default_shipping_address_id", '');
      }
    } else {
      formData.append("default_shipping_address_id", default_shipping_address_id);
    }

    //update user
    updateUser(formData).then((response) => {
      if (response.code === 200) {
        if (response.data.status === "success") {
          alert("User updated successfully");
          handleClose();
        } else {
          alert(response.data.message);
          setEdituser((prevUserData) => ({
            ...prevUserData,
            error: response.data.message,
          }));
        }
        setDelcount(delcount + 1);
      } else {
        alert("Something went wrong");
        handleClose();
      }
    }).catch((error) => {
      alert("Something went wrong");
      handleClose();
    }).finally(() => {
      props.onCloseidChange(0);
    })
    // setValidated(false);
    // }
  };

  const [searchorderid, setSearchOrderId] = useState("");
  const [expedtedendtime, setExpedtedendtime] = useState();

  const getHoursMinutes = (time) => {
    const hours = Math.floor(time / 60);
    const minutes = time % 60;

    return `${hours} hours and ${minutes} minutes`;
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



  const [show02, setShow02] = useState(false);

  const handleClose02 = () => setShow02(false);
  const handleShow02 = () => setShow02(true);

  const [selected, setSelected] = useState([]);

  const datetimeFormatter = (cell, row) => {
    return moment(cell).format("DD MMM YYYY hh:mm");
  };

  const getStatusofbookings = (bookingstatus = "", book_by = "") => {

    var book_by;
    if (book_by === "0") {
      book_by = "Admin";
    }
    else if (book_by === "1") {
      book_by = "Client";
    }
    else if (book_by === "2") {
      book_by = "Technician ";
    }

    if (bookingstatus === "A") {
      return "Active (unconfirmed booked by " + book_by + ")";
    } else if (bookingstatus === "C") {
      return "Confirmed (confirmed by admin)";
    } else if (bookingstatus === "R") {
      return "Reject";
    } else if (bookingstatus === "CC") {
      return "Cancel by Client";
    } else if (bookingstatus === "CS") {
      return "Cancel by service provider";
    } else if (bookingstatus === "CO") {
      return "Completed";
    } else if (bookingstatus === "MN") {
      return "Mark as no show,";
    } else if (bookingstatus === "UC") {
      return "Confirmed by Client";
    } else {
      return "";
    }
  };

  const states = [
    { name: "Alabama", cities: ["Birmingham", "Montgomery", "Mobile"], stateCode: "AL" },
    { name: "Alaska", cities: ["Anchorage", "Fairbanks", "Juneau"], stateCode: "AK" },
    { name: "Arizona", cities: ["Phoenix", "Tucson", "Mesa"], stateCode: "AZ" },
    { name: "Arkansas", cities: ["Little Rock", "Fort Smith", "Fayetteville"], stateCode: "AR" },
    { name: "California", cities: ["Los Angeles", "San Diego", "San Jose"], stateCode: "CA" },
    { name: "Colorado", cities: ["Denver", "Colorado Springs", "Aurora"], stateCode: "CO" },
    { name: "Connecticut", cities: ["Bridgeport", "New Haven", "Hartford"], stateCode: "CT" },
    { name: "Delaware", cities: ["Wilmington", "Dover", "Newark"], stateCode: "DE" },
    { name: "Florida", cities: ["Jacksonville", "Miami", "Tampa"], stateCode: "FL" },
    { name: "Georgia", cities: ["Atlanta", "Augusta", "Columbus"], stateCode: "GA" },
    { name: "Hawaii", cities: ["Honolulu", "Pearl City", "Hilo"], stateCode: "HI" },
    { name: "Idaho", cities: ["Boise", "Nampa", "Meridian"], stateCode: "ID" },
    { name: "Illinois", cities: ["Chicago", "Aurora", "Rockford"], stateCode: "IL" },
    { name: "Indiana", cities: ["Indianapolis", "Fort Wayne", "Evansville"], stateCode: "IN" },
    { name: "Iowa", cities: ["Des Moines", "Cedar Rapids", "Davenport"], stateCode: "IA" },
    { name: "Kansas", cities: ["Wichita", "Overland Park", "Kansas City"], stateCode: "KS" },
    { name: "Kentucky", cities: ["Louisville", "Lexington", "Bowling Green"], stateCode: "KY" },
    { name: "Louisiana", cities: ["New Orleans", "Baton Rouge", "Shreveport"], stateCode: "LA" },
    { name: "Maine", cities: ["Portland", "Lewiston", "Bangor"], stateCode: "ME" },
    { name: "Maryland", cities: ["Baltimore", "Frederick", "Rockville"], stateCode: "MD" },
    { name: "Massachusetts", cities: ["Boston", "Worcester", "Springfield"], stateCode: "MA" },
    { name: "Michigan", cities: ["Detroit", "Grand Rapids", "Warren"], stateCode: "MI" },
    { name: "Minnesota", cities: ["Minneapolis", "Saint Paul", "Rochester"], stateCode: "MN" },
    { name: "Mississippi", cities: ["Jackson", "Gulfport", "Southaven"], stateCode: "MS" },
    { name: "Missouri", cities: ["Kansas City", "Saint Louis", "Springfield"], stateCode: "MO" },
    { name: "Montana", cities: ["Billings", "Missoula", "Great Falls"], stateCode: "MT" },
    { name: "Nebraska", cities: ["Omaha", "Lincoln", "Bellevue"], stateCode: "NE" },
    { name: "Nevada", cities: ["Las Vegas", "Henderson", "Reno"], stateCode: "NV" },
    { name: "New Hampshire", cities: ["Manchester", "Nashua", "Concord"], stateCode: "NH" },
    { name: "New Jersey", cities: ["Newark", "Jersey City", "Paterson"], stateCode: "NJ" },
    { name: "New Mexico", cities: ["Albuquerque", "Las Cruces", "Rio Rancho"], stateCode: "NM" },
    { name: "New York", cities: ["New York", "Buffalo", "Rochester"], stateCode: "NY" },
    { name: "North Carolina", cities: ["Charlotte", "Raleigh", "Greensboro"], stateCode: "NC" },
    { name: "North Dakota", cities: ["Fargo", "Bismarck", "Grand Forks"], stateCode: "ND" },
    { name: "Ohio", cities: ["Columbus", "Cleveland", "Cincinnati"], stateCode: "OH" },
    { name: "Oklahoma", cities: ["Oklahoma City", "Tulsa", "Norman"], stateCode: "OK" },
    { name: "Oregon", cities: ["Portland", "Salem", "Eugene"], stateCode: "OR" },
  ];

  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
    setState(event.target.value);
  };

  // const [start, setStart] = useState(moment(new Date(2016, 8, 20, 0, 0, 0, 0)));
  const [start, setStart] = useState(moment());
  const [end, setEnd] = useState(moment(start).add(5, "days").subtract(1, "second"));
  const [pagenumber, setPagnumber] = useState(0);


  const applyCallback = (startDate, endDate) => {
    console.log(startDate.format("DD-MM-YYYY HH:mm:ss"));
    console.log(endDate.format("DD-MM-YYYY HH:mm:ss"));
    setStart(startDate);
    setEnd(endDate);
  };




  const [sdate, setSdate] = useState("2000-01-01");
  const [edate, setEdate] = useState("2050-12-31");
  // const sevenDaysLater = new Date();
  // sevenDaysLater.setDate(sevenDaysLater.getDate() + 7);


  const [dates, setDates] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection'
    }
  ]);


  useEffect(() => {
    const startDate = new Date(sdate);
    const endDate = new Date(edate);

    setDates([
      {
        startDate: startDate,
        endDate: endDate,
        key: 'selection'
      }
    ]);
  }, [sdate, edate]);

  const [showDateDisplay, setShowDateDisplay] = useState(false);


  // useEffect(() => {
  //   if (start != undefined && end != undefined) {
  //     const { page, sizePerPage, filters, sortField, sortOrder } = tableConfig;

  //     getbookingdata(
  //       page,
  //       sizePerPage,
  //       JSON.stringify(filters),
  //       sortField,
  //       sortOrder
  //     );
  //   }
  // }, [props.id]);

  const [orginalebookigdata, setOrginalebookigdata] = useState([]);
  const [loadingsppiner, setLoadingSpinner] = useState(false);


  const [pageOption, setPageOption] = React.useState({
    totalSize: 0,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
  });


  const [tableConfig, setTableConfig] = React.useState({
    page: 1,
    sizePerPage: 10,
    filters: {},
    sortField: "",
    sortOrder: "",
  });


  // const paginationOptions = {
  //   ...pageOption, // Your existing options
  //   page: tableConfig.page, // Current page from state
  //   sizePerPage: tableConfig.sizePerPage, // Current size per page from state
  // };


  const handleTableChange = (type, { page, sizePerPage, filters, sortField, sortOrder }) => {

    // setTableConfig({ page, sizePerPage, filters, sortField, sortOrder });

    setTableConfig(prevConfig => ({
      ...prevConfig,
      page,
      sizePerPage,
      filters,
      sortField,
      sortOrder,
    }));


    if (sortField === null || sortField === undefined) {
      sortField = "";
    }
    if (sortOrder === undefined || sortOrder === null) {
      sortOrder = "";
    }
    getbookingdata(page, sizePerPage, filters, sortField, sortOrder);
  };



  const handleButtonClick = () => {
    const { page, sizePerPage, filters, sortField, sortOrder } = tableConfig;
    getbookingdata(page, sizePerPage, filters, sortField, sortOrder);
  };


  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);


  const getbookingdata = async (page, sizePerPage, filters, sortField, sortOrder) => {
    setLoadingSpinner(true);
    // setLoading(true);
    const formData = new FormData();
    formData.append("user_id", props.id);
    // formData.append("technician", searchorderid);
    formData.append("start_date", moment(sdate).format("YYYY-MM-DD"));
    formData.append("end_date", moment(edate).format("YYYY-MM-DD"));
    formData.append("page", page);
    formData.append("page_size", sizePerPage);
    formData.append("filters", filters);
    formData.append("order_by", sortField);
    formData.append("order_type", sortOrder);
    formData.append("searchText", searchorderid);


    getUserBooking(formData).then((response) => {
      if (response.code === 200) {
        if (response.data.status == "true") {
          const bookingResponse = response.data;
          setBookingdata(bookingResponse.data.bookings);
          setOrginalebookigdata(bookingResponse.data.bookings);
          setPageOption({
            totalSize: bookingResponse.data.total_rows,
            hideSizePerPage: false,
            hidePageListOnlyOnePage: true,
          });
        } else {
          setBookingdata([]);
        }
      } else {
        alert("Something went wrong");
      }
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      setLoadingSpinner(false);
      // setLoading(false);
      handleClose9();
    });
  };


  useEffect(() => {
    const { page, sizePerPage, filters, sortField, sortOrder } = tableConfig;

    getbookingdata(
      page,
      sizePerPage,
      JSON.stringify(filters),
      sortField,
      sortOrder
    );
    getusershipppingaddress(props.id);
  }, [props.id]);




  useEffect(() => {
    const { page, sizePerPage, filters, sortField, sortOrder } = tableConfig;

    if (searchorderid !== undefined) {
      getbookingdata(
        page,
        sizePerPage,
        JSON.stringify(filters),
        sortField,
        sortOrder,
        searchorderid
      );
    }

    if (searchorderid === "") {
      setBookingdata(orginalebookigdata);
    }
  }, [searchorderid]);





  // useEffect(() => {

  //   if (searchorderid !== undefined) {
  //     const filteredData = bookingdata.filter((booking) => {

  //       if (booking.staff_ids) {
  //         // const staffIdsArray = booking.staff_ids.split(',').map(id => id.trim());
  //         const staffIdsArray = booking.staff_ids
  //           .split(',')
  //           .map(id => id.trim());
  //         return staffIdsArray.includes(searchorderid);
  //       }

  //       return false; // Handle the case when staff_ids is undefined or empty
  //     });

  //     setBookingdata(filteredData);
  //   }

  //   if (searchorderid === "") {
  //     setBookingdata(orginalebookigdata);
  //   }

  //   handleClose9();

  // }, [searchorderid])




  const rangeCallback = (index, value) => {
    console.log(index, value);
  };





  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
    setCity(event.target.value);
  };

  useEffect(() => {
    setSelectedState(states[0].name);
    setState(states[0].name);

    setCustomerstage("New Lead");
    productlistdata();
  }, []);

  const [show3, setShow3] = useState(false);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);



  // const cities = selectedState
  //   ? states.find((state) => state.stateCode === selectedState).cities
  //   : [];

  const [editbookingstate, setEditbookingstate] = useState(" ");
  const [editbooking, setEditbooking] = useState([]);
  const [EditSubServices, setEditSubServices] = useState([]);
  const [editaddons, setEditAddons] = useState([]);
  const [EditbookingStartDate, setEditbookingStartDate] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState(null);
  const [EditbookingStartTime, setEditbookingStartTime] = useState(null);
  const [EditbookingEndTime, setEditbookingEndTime] = useState(null);
  const [EditbookingEndDate, setEditbookingEndDate] = useState(null);
  const [assignStaff, setAssignStaff] = useState([]);
  const [edittotalAmount, setEditTotalAmount] = useState(0);
  const [edittotalDuration, setEditTotalDuration] = useState(0);
  const [editbaseTime, setEditBaseTime] = useState("00:00");
  const [rescheduleDetails, setRescheduleDetails] = useState([]);
  const [show5, setShow5] = useState(false);

  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const getbookingStartDate = (bookingdatetime = "") => {
    const bookingDateTime = bookingdatetime.split(" ");
    const bookingDate = bookingDateTime[0];
    return bookingDate;
  }

  const [editstart, setEditstart] = useState(false);

  function convertTimeTo24Hour(time) {
    // Split the time into hours, minutes, and AM/PM components
    const [timeString, modifier] = time.split(' ');
    const [hours, minutes] = timeString.split(':');

    // Convert hours to 24-hour format
    let hours24 = parseInt(hours, 10);
    if ((modifier == 'pm' || modifier == 'PM') && hours24 !== 12) {
      hours24 += 12;
    } else if ((modifier == 'am' || modifier == 'AM') && hours24 === 12) {
      hours24 = 0;
    }

    // Pad the hours and minutes with leading zeros if necessary
    const formattedHours = String(hours24).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');

    // Return the time in 24-hour format
    return `${formattedHours}:${formattedMinutes}`;
  }
  const getbookingStartTime = (start_time = "") => {
    var start_time = start_time.split(" ");
    var start_time1 = start_time[0].split(":");
    var starttime = start_time1[0] + ":" + start_time1[1];
    return starttime;
  }

  const handlechageeditamount = (value) => {
    if (value != "") {
      var value = parseInt(value);
      setEditbookingestimatedPrice(value);
      const service_percentage = parseInt(editbooking.service_percentage);
      const totalAmountvar1 = ((100 - service_percentage) * value) / 100;
      setEditTotalAmount(parseInt(totalAmountvar1));
    }
    else {
      setEditTotalAmount(0);
      setEditbookingestimatedPrice(0);
    }
  }

  const convertToMinutes = (time) => {
    const [hours, minutes] = time.split(':');
    return parseInt(hours) * 60 + parseInt(minutes);
  }


  const handlechageedittime = (event) => {
    const inputValue = event.target.value;
    const [hours, minutes] = inputValue.split(':');
    const validMinutes = Math.min(Math.max(parseInt(minutes || 0, 10), 0), 59);
    const formattedValue = `${hours || '00'}:${validMinutes.toString().padStart(2, '0')}`;
    let totalDurationvar = convertToMinutes(formattedValue)
    setEditbookingestimatedDuration(totalDurationvar);


    // const totalDurationvarwithextra = (totalDurationvar * ) / 11;
    const totalDurationvarwithextra = ((100 - editbooking.service_percentage) * totalDurationvar) / 100;

    // if (assignStaff.length == null || assignStaff.length == 0) {
    //   setEditBaseTime(parseInt(totalDurationvarwithextra));
    // }
    // else {
    setEditBaseTime(parseInt(totalDurationvarwithextra / assignStaff.length));
    // }



  };

  const [editbookingestimatedPrice, setEditbookingestimatedPrice] = useState(0);
  const [editbookingestimatedDuration, setEditbookingestimatedDuration] = useState("00:00");



  useEffect(() => {
    // if (editstart) {

    const hours = parseInt(editbooking.hours) * 60;
    const mints = parseInt(editbooking.mints);
    const totalDuration = hours + mints;
    if (editbooking.estimated_price == null || editbooking.estimated_price == "") {
      setEditbookingestimatedPrice(editbooking.billed_amount);

      var Servicepercentage = parseInt(editbooking.service_percentage);
      const percentage = (editbooking.billed_amount * Servicepercentage) / 100;
      const totalAmountvar1 = totalDuration + percentage;
      setEditTotalAmount(totalAmountvar1);

    } else {
      const priceRange = editbooking.estimated_price;
      // const [minPriceStr, maxPriceStr] = priceRange.split(' to ');

      const [minPriceStr, maxPriceStr] = priceRange.includes(' to ') ? priceRange.split(' to ') : priceRange.split(' - ');

      // Remove the dollar sign and convert the strings to numbers
      const minPrice = parseFloat(minPriceStr.replace('$', ''));
      const maxPrice = parseFloat(maxPriceStr.replace('$', ''));

      setEditTotalAmount(minPrice)
      setEditbookingestimatedPrice(maxPrice);
    }

    if (editbooking.estimated_duration == null || editbooking.estimated_duration == "") {
      setEditbookingestimatedDuration(editbooking.order_duration);

      var Servicepercentage = parseInt(editbooking.service_percentage);
      const percentage = (totalDuration * Servicepercentage) / 100;
      const totalAmountvar1 = totalDuration + percentage;
      setEditBaseTime(totalAmountvar1);

    } else {
      const estimated_duration = editbooking.estimated_duration;


      // const [startTime, endTime] = estimated_duration.split(' to ');
      const [startTime, endTime] = estimated_duration.includes(' to ') ? estimated_duration.split(' to ') : estimated_duration.split(' - ');

      // Convert start and end times to minutes
      const [startHours, startMinutes] = startTime.split(':');
      const [endHours, endMinutes] = endTime.split(':');
      const startInMinutes = parseInt(startHours) * 60 + parseInt(startMinutes);
      const endInMinutes = parseInt(endHours) * 60 + parseInt(endMinutes);


      setEditbookingestimatedDuration(endInMinutes);

      setEditBaseTime(startInMinutes);

    }

    // setEditTotalDuration(editbaseTime);


    // }
  }, [editbooking.hours, editbooking.mints, editbooking.billed_amount]);


  useEffect(() => {
    const staffids = editbooking.staff_ids;

    if (Array.isArray(staffids)) {
      const staff_length = staffids.length > 0 ? staffids.length : 1;
      setUpdatestaff(staffids);
      setExpedtedendtime(parseInt(editbookingestimatedDuration / staff_length));
    } else {
      console.error("editbooking.staff_ids is not an array or is undefined.");
    }
  }, [editbooking.staff_ids, editbookingestimatedDuration]);



  useEffect(() => {
    if (editstart) {
      calculateEditPriceforbooking();
    }
  }, [EditSubServices, editaddons]);

  useEffect(() => {
    const staff_length = assignStaff.length > 0 ? assignStaff.length : 1;
    setExpedtedendtime(parseInt(editbookingestimatedDuration / staff_length));
  }, [assignStaff, editbookingestimatedDuration]);


  const calculateEditPriceforbooking = () => {
    var totalAmountvar = 0;
    var totalDurationvar = 0;
    EditSubServices.map((item) => {
      const basePrice = parseInt(item.base_price);
      const baseCount = 1;
      const baseDuration = parseInt(item.base_duration);
      const extraCount = parseInt(item.quantity) - baseCount;
      const extraPrice = parseInt(item.base_price_extra_unit);
      const extraDuration = parseInt(item.extra_duration_per_unit_in_minute);
      const baseAmount = basePrice * baseCount;
      const extraAmount = extraPrice * extraCount;
      totalAmountvar = totalAmountvar + baseAmount + extraAmount;
      totalDurationvar = totalDurationvar + baseDuration + extraCount * extraDuration;
    });
    editaddons.map((item) => {
      if (item.addons_service_qty == 0 || item.addons_service_qty == null) {

      }
      else {

        const basePrice = parseInt(item.base_price);
        const baseCount = 1;
        const baseDuration = parseInt(item.base_duration);;
        const extraCount = parseInt(item.addons_service_qty) - baseCount;
        const extraPrice = parseInt(item.price_for_per_extra_unit);
        const extraDuration = parseInt(item.extra_minute_per_unit);
        const baseAmount = basePrice * baseCount;
        const extraAmount = extraPrice * extraCount;
        totalAmountvar = totalAmountvar + baseAmount + extraAmount;
        totalDurationvar = totalDurationvar + baseDuration + extraCount * extraDuration;

      }
    });
    setEditTotalAmount(totalAmountvar);
    const staff_length = assignStaff.length > 0 ? assignStaff.length : 1;
    // setEditTotalDuration(parseInt(totalDurationvar / staff_length));

    setEditTotalDuration(parseInt(totalDurationvar));


    //get percentage from totalAmount
    var Servicepercentage = parseInt(editbooking.service_percentage);
    const percentage = (totalAmountvar * Servicepercentage) / 100;
    const totalAmountvar1 = totalAmountvar + percentage;
    setEditbookingestimatedPrice(totalAmountvar1);


    //get percentage from totalDurationvar
    const extrarange = (totalDurationvar * Servicepercentage) / 100;
    const totalDurationvarwithextra = totalDurationvar + extrarange;
    // setEditbookingestimatedDuration(parseInt(totalDurationvarwithextra / staff_length));
    setEditbookingestimatedDuration(parseInt(totalDurationvarwithextra));
    setExpedtedendtime(parseInt(totalDurationvarwithextra / staff_length))
  }

  useEffect(() => {
    getEditTimeData();
  }, [edittotalDuration]);

  const getEditTimeData = () => {
    // const start_time = edittotalDuration;
    // var hours = Math.floor(start_time / 60);
    // var minutes = start_time % 60;

    // if (hours < 10) {
    //   hours = "0" + hours;
    // }
    // else {
    //   hours = hours;
    // }
    // if (minutes < 10) {
    //   minutes = "0" + minutes;
    // }
    // else {
    //   minutes = minutes;
    // }

    // var time = hours + ":" + minutes;
    setEditBaseTime(edittotalDuration);
  }


  function changeCounter3(value, id) {
    if (value === "increment") {
      const updatedArray = EditSubServices.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            quantity: parseInt(item.quantity) + 1,
          };
        }

        return item;
      });
      setEditSubServices(updatedArray);
    } else if (value === "decrement") {
      const updatedArray = EditSubServices.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            quantity: parseInt(item.quantity) - 1,
          };
        }

        return item;
      });
      setEditSubServices(updatedArray);
    }
  }


  function changeeditaddons(value, id) {
    if (value === "increment") {
      const updatedArray = editaddons.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            addons_service_qty: parseInt(item.addons_service_qty) + 1,
          };
        }

        return item;
      });

      setEditAddons(updatedArray);
    } else if (value === "decrement") {
      const updatedArray = editaddons.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            addons_service_qty: parseInt(item.addons_service_qty) - 1,
          };
        }

        return item;
      });
      setEditAddons(updatedArray);
    }
  }


  const [updatestaff, setUpdatestaff] = useState([]);
  const [bookingid, setBookingid] = useState("");
  const [staffList, setStaffList] = useState(null);
  const [selectstaffs, setSelectstaffs] = useState([]);
  const [rescheduleDate, setRescheduleDate] = useState(rescheduleDetails.order_date);
  const [reschedunotes, setReschedulenotes] = useState(rescheduleDetails.order_time);
  const [reschedulestaff, setReschedulestaff] = useState(rescheduleDetails.staff_ids);


  const getStaffList = () => {
    getactivestaff().then((res) => {
      if (res.code === 200) {
        const apiResponse = res.data;
        setStaffList(apiResponse.data);
      }
    })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (props.id != 0) {
      setPagnumber(0);
      getStaffList();
      setShow(true);
      handleedit(props.id);
    }
  }, [props.id]);

  const handleedit = (id) => {
    const formData = new FormData();
    formData.append("column", "id");
    formData.append("value", id);

    //get user data
    getuserbycolumn(formData).then((response) => {
      if (response.code === 200) {
        const apiResponse = response.data;
        setEdituser(apiResponse.data);
        setUpdateemail(apiResponse.data.user_email);
        setProfileImagePreview(apiResponse.data.image);
        setDefaultshippingaddressid(apiResponse.data.default_shipping_address_id);
        setshippingstate(apiResponse.data.shipping_state);
        setState(apiResponse.data.state);
        // const bydefault = usershippingaddress.find(option => option.value.trim() === defaultshippingaddressid.trim());
        // setSelectedOption(bydefault);
      }
    }).catch((err) => {
      console.log(err);
    }).finally(() => {
      handleShow();
    })
  };

  useEffect(() => {
    if (defaultshippingaddressid && Array.isArray(usershippingaddress)) {
      const bydefault = usershippingaddress.find(option =>
        option.value.trim() === defaultshippingaddressid.trim()
      );
      setSelectedOption(bydefault ? [bydefault] : []);
    }
  }, [defaultshippingaddressid, usershippingaddress]);


  const getEditbooking = (order_id) => {
    handleShow3();
    setLoading(true);

    setBookingid(order_id);
    const formData = new FormData();
    formData.append("order_id", order_id);
    formData.append("client_id", props.id);

    getEditbookingData(formData).then((response) => {
      if (response.code === 200) {
        if (response.data.status === "1") {
          const apiResponse = response.data;
          setEditbooking(apiResponse.data);
          setUpdatestaff(apiResponse.data.staff_ids);
          const staff_ids = apiResponse.data.staff_ids;
          const staff_ids_array = staff_ids.split(",");
          const staff_ids_index = [];
          staff_ids_array.forEach((staff_id) => {
            const index = staffList.findIndex((staff) => staff.value === staff_id);
            staff_ids_index.push(index);
          });
          const selectedStaff = [];
          staff_ids_index.forEach((index) => {
            selectedStaff.push(staffList[index]);
          });
          setSelectedOptions(selectedStaff);

          const start_time = apiResponse.data.start_time;
          // const start_time = getbookingStartTime(apiResponse.data.start_time);

          setEditbookingStartTime(convertTimeTo24Hour(start_time));

          // setEditbookingStartTime(start_time);

          const end_time = getbookingStartTime(apiResponse.data.end_time);
          setEditbookingEndTime(end_time);

          const start_d_time = getbookingStartDate(apiResponse.data.booking_date_time);
          setEditbookingStartDate(start_d_time);

          const end_d_time = getbookingStartDate(apiResponse.data.booking_date_time);
          setEditbookingEndDate(end_d_time);

          setEditSubServices(apiResponse.data.subservices);
          setEditAddons(apiResponse.data.addons);
          const staff = apiResponse.data.staff_ids;
          setAssignStaff(selectedStaff);



        } else {
          alert("Something went wrong");
        }
      } else {
        alert("Something went wrong");
      }
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      setLoading(false);
    })

  }

  const [totalAmount, setTotalAmount] = useState(0);



  const handleEditInputChange3 = (event) => {
    const { name, value } = event.target;
    setEditbooking((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  const UpdateBookigData = () => {

    // const checktime = moment().format("HH:mm");
    if (EditbookingStartTime < "07:00" || EditbookingStartTime > "21:00") {
      NotificationManager.error("⚠ Please select time between 07:00 AM to 08:00 PM");
      return;
    }

    const formData = new FormData();
    formData.append("order_id", bookingid);
    formData.append("client_id", edituser.id);
    formData.append("start_date", EditbookingStartDate);
    formData.append("start_d_time", EditbookingStartTime);
    formData.append("service_amount", Math.round(editbookingestimatedPrice));
    formData.append("duration", editbookingestimatedDuration);
    formData.append("appointment_note_by_staff", editbooking.appointment_note);
    formData.append("appointment_zip", editbooking.appointment_zip);
    formData.append("appointment_address", editbooking.client_address);
    formData.append("appointment_city", editbooking.appointment_city);
    formData.append("appointment_state", editbookingstate);
    formData.append("client_name", editbooking.client_name);


    formData.append("estimated_price", "$" + edittotalAmount + " to " + "$" + Math.round(editbookingestimatedPrice));
    formData.append("estimated_duration", convertToHHMM(editbaseTime) + " to " + convertToHHMM(editbookingestimatedDuration));



    const subServices = [];
    for (let i = 0; i < EditSubServices.length; i++) {
      // subServices.push(EditSubServices[i].quantity);
      subServices.push({ id: EditSubServices[i].booking_id, quantity: EditSubServices[i].quantity })
    }
    formData.append("method_unit_selected_values", JSON.stringify(subServices));

    const staffIds = [];

    if (assignStaff != null && assignStaff != "" && assignStaff != undefined && assignStaff.length > 0) {

      for (let i = 0; i < assignStaff.length; i++) {
        staffIds.push(assignStaff[i].value);
      }
      formData.append("staff_ids", staffIds);

    }
    else {
      // const staffIds = [];

      // updatestaff.map((item) => {
      //   staffIds.push(item);
      // });

      formData.append("staff_ids", 79);
    }




    const addons = [];
    for (let i = 0; i < editaddons.length; i++) {
      addons.push({ id: editaddons[i].id, quantity: editaddons[i].addons_service_qty, type: editaddons[i].new });
    }
    formData.append("addons_service_qty", JSON.stringify(addons));

    updateBookingData(formData).then((response) => {
      if (response.code === 200) {
        if (response.data.status === "1") {
          getEditbooking(bookingid);
          handleClose3();
          NotificationManager.success('Booking updated successfully');
        } else {
          alert("Something went wrong");
        }
      } else {
        alert("Something went wrong");
      }
    }).catch((error) => {
      //console.log(error);
    }).finally(() => {
      handleClose3();
    })
  }


  const [apicall, setApicall] = useState(false);


  const updaterecurringbooking = (recurring_id) => {

    if (EditbookingStartTime < "07:00" || EditbookingStartTime > "21:00") {
      NotificationManager.error("⚠ Please select time between 07:00 AM to 08:00 PM");
      return;
    }

    setApicall(true);
    const formData = new FormData();
    formData.append("recurring_id", recurring_id);
    formData.append("client_id", edituser.id);
    formData.append("start_date", EditbookingStartDate);
    formData.append("start_d_time", EditbookingStartTime);
    formData.append("service_amount", Math.round(editbookingestimatedPrice));
    formData.append("duration", editbookingestimatedDuration);
    formData.append("appointment_note_by_staff", editbooking.appointment_note);
    formData.append("appointment_zip", editbooking.appointment_zip);
    formData.append("appointment_address", editbooking.client_address);
    formData.append("appointment_city", editbooking.appointment_city);
    formData.append("appointment_state", editbookingstate);
    formData.append("client_name", editbooking.client_name);


    formData.append("estimated_price", "$" + edittotalAmount + " to " + "$" + Math.round(editbookingestimatedPrice));
    formData.append("estimated_duration", convertToHHMM(editbaseTime) + " to " + convertToHHMM(editbookingestimatedDuration));



    const subServices = [];
    for (let i = 0; i < EditSubServices.length; i++) {
      // subServices.push(EditSubServices[i].quantity);
      subServices.push({ id: EditSubServices[i].booking_id, method_id: EditSubServices[i].id, quantity: EditSubServices[i].quantity })
    }
    formData.append("method_unit_selected_values", JSON.stringify(subServices));

    const staffIds = [];

    if (assignStaff != null && assignStaff != "" && assignStaff != undefined && assignStaff.length > 0) {

      for (let i = 0; i < assignStaff.length; i++) {
        staffIds.push(assignStaff[i].value);
      }
      formData.append("staff_ids", staffIds);

    }
    else {
      // const staffIds = [];

      // updatestaff.map((item) => {
      //   staffIds.push(item);
      // });

      formData.append("staff_ids", 79);
    }




    const addons = [];
    for (let i = 0; i < editaddons.length; i++) {
      addons.push({ id: editaddons[i].addon_id, quantity: editaddons[i].addons_service_qty, type: editaddons[i].new });
    }
    formData.append("addons_service_qty", JSON.stringify(addons));

    updaterecurringbookingAPi(formData).then((response) => {
      if (response.code === 200) {
        if (response.data.status === "1") {
          alert("Booking updated successfully");
          window.location.reload();
        } else {
          alert("Something went wrong");
        }
      } else {
        alert("Something went wrong");
      }
    }).catch((error) => {
      //console.log(error);
    }).finally(() => {
      setApicall(false);
      handleClose3();
    })

  }

  const renderVanillaPicker = (ranges, local, maxDate) => {
    const value = `${start.format("DD-MM-YYYY HH:mm")} - ${end.format("DD-MM-YYYY HH:mm")}`;
    const disabled = false;



    return (
      <div>
        {/* <br /> */}
        <DateTimeRangeContainer
          ranges={ranges}
          start={start}
          end={end}
          local={local}
          // maxDate={maxDate}
          applyCallback={applyCallback}
          rangeCallback={rangeCallback}
          smartMode
        >
          <FormControl
            id="formControlsTextB"
            type="text"
            label="Text"
            placeholder="Enter text"
            style={{ cursor: "pointer" }}
            // disabled={disabled}
            value={value}
          />
        </DateTimeRangeContainer>
        {/* <br /> */}
      </div>
    );
  };

  let now = new Date();

  let ranges = {
    "Today Only": [moment(start), moment(end)],
    "Yesterday Only": [
      moment(start).subtract(1, "days"),
      moment(end).subtract(1, "days")
    ],
    "3 Days": [moment(start).subtract(3, "days"), moment(end)],
    "5 Days": [moment(start).subtract(5, "days"), moment(end)],
    "1 Week": [moment(start).subtract(7, "days"), moment(end)],
    "2 Weeks": [moment(start).subtract(14, "days"), moment(end)],
    "1 Month": [moment(start).subtract(1, "months"), moment(end)],
    "1st August 18": [
      moment("2018-08-01 00:00:00"),
      moment("2018-08-02 23:59:59")
    ],
    "1 Year": [moment(start).subtract(1, "years"), moment(end)]
  };

  let local = {
    format: "DD-MM-YYYY HH:mm",
    sundayFirst: false
  };

  let maxDate = moment(end).add(24, "hour");

  let pickersRender = (
    <div>
      {/* <br /> */}
      {renderVanillaPicker(ranges, local, maxDate)}
    </div>
  );

  const complatebooking = (order_id) => {
    if (window.confirm("Are you sure you want to complete this booking?")) {
      const formData = new FormData();
      formData.append("order_id", order_id);
      complatebookingstatus(formData).then((response) => {
        if (response.code === 200) {
          if (response.data.status === "1") {
            alert("Booking completed successfully");
            const { page, sizePerPage, filters, sortField, sortOrder } = tableConfig;
            getbookingdata(page, sizePerPage, filters, sortField, sortOrder);
          } else {
            alert("Something went wrong");
          }
        } else {
          alert("Something went wrong");
        }
      }).catch((error) => {
        console.log(error);
      });
    }
  }

  const uncomplatebooking = (order_id) => {
    if (window.confirm("Are you sure you want to uncomplete this booking?")) {
      const formData = new FormData();
      formData.append("order_id", order_id);
      uncomplatebookingstatus(formData).then((response) => {
        if (response.code === 200) {
          if (response.data.status === "1") {
            alert("Booking uncompleted successfully");
            const { page, sizePerPage, filters, sortField, sortOrder } = tableConfig;
            getbookingdata(page, sizePerPage, filters, sortField, sortOrder);
          } else {
            alert("Something went wrong");
          }
        } else {
          alert("Something went wrong");
        }
      }).catch((error) => {
        console.log(error);
      });
    }
  }

  const unconfirmbooking = (order_id) => {
    if (window.confirm("Are you sure you want to unconfirm this booking?")) {
      const formData = new FormData();
      formData.append("order_id", order_id);
      unconfirmbookingstatus(formData).then((response) => {
        if (response.code === 200) {

          if (response.data.status === "1") {
            alert("Booking unconfirmed successfully");
            const { page, sizePerPage, filters, sortField, sortOrder } = tableConfig;
            getbookingdata(page, sizePerPage, filters, sortField, sortOrder);
          } else {
            alert("Something went wrong");
          }
        } else {
          alert("Something went wrong");
        }
      }).catch((error) => {
        console.log(error);
      });
    }
  }

  const confirmbooking = (order_id) => {
    //confirm alert
    if (window.confirm("Are you sure you want to confirm this booking?")) {
      const formData = new FormData();
      formData.append("order_id", order_id);
      confirmbookingstatus(formData).then((response) => {
        if (response.code === 200) {
          if (response.data.status === "1") {
            alert("Booking confirmed successfully");
            const { page, sizePerPage, filters, sortField, sortOrder } = tableConfig;
            getbookingdata(page, sizePerPage, filters, sortField, sortOrder);
          } else {
            alert("Something went wrong");
          }
        } else {
          alert("Something went wrong");
        }
      }).catch((error) => {
        console.log(error);
      });
    }
  }

  const deletebooking = (order_id) => {
    if (window.confirm("Are you sure you want to delete this booking?")) {
      const formData = new FormData();
      formData.append("order_id", order_id);
      deletebookingApi(formData).then((response) => {
        if (response.code === 200) {
          if (response.data.status === "1") {
            alert("Booking delete successfully");
            const { page, sizePerPage, filters, sortField, sortOrder } = tableConfig;
            getbookingdata(page, sizePerPage, filters, sortField, sortOrder);
          } else {
            alert("Something went wrong");
          }
        } else {
          alert("Something went wrong");
        }
      }).catch((error) => {
        console.log(error);
      });
    }
  }

  const activequotebooking = (order_id) => {
    //confirm alert
    if (window.confirm("Are you sure you want to active this booking?")) {
      const formData = new FormData();
      formData.append("order_id", order_id);
      addbookingbookingstatus(formData).then((response) => {
        if (response.code === 200) {
          if (response.data.status === "1") {
            // alert("Booking confirmed successfully");
            // getbookingsfunc();
            // window.location.reload();
            alert("Booked successfully");
            const { page, sizePerPage, filters, sortField, sortOrder } = tableConfig;
            getbookingdata(page, sizePerPage, filters, sortField, sortOrder);
          } else {
            alert("Something went wrong");
          }
        } else {
          alert("Something went wrong");
        }
      }).catch((error) => {
        console.log(error);
      });
    }
  }

  const deletereccuringbooking = (order_id, booking_date) => {
    if (window.confirm("Are you sure you want to delete this booking?")) {
      const formData = new FormData();
      formData.append("recurring_id", order_id);
      formData.append("booking_date", booking_date);

      reccuringdeletebooking(formData).then((response) => {
        if (response.code === 200) {
          if (response.data.status === "1") {
            NotificationManager.success("Booking delete successfully");
            handleClose4();
            window.location.reload();
          } else {
            alert("Something went wrong");
          }
        } else {
          alert("Something went wrong");
        }
      }).catch((error) => {
        console.log(error);
      })
    }
  }



  const activebooking = (order_id) => {
    if (window.confirm("Are you sure you want to active this booking?")) {
      const formData = new FormData();
      formData.append("order_id", order_id);
      activebookingstatus(formData).then((response) => {
        if (response.code === 200) {
          if (response.data.status === "1") {
            alert("Booking active successfully");
            getbookingdata();
          } else {
            alert("Something went wrong");
          }
        } else {
          alert("Something went wrong");
        }
      }).catch((error) => {
        console.log(error);
      });
    }

  }



  const rescheduleData = (order_id) => {
    handleShow5();
    setBookingid(order_id);
    const formData = new FormData();
    formData.append("order_id", order_id);

    getrescheduledata(formData).then((response) => {
      if (response.code === 200) {
        if (response.data.status === "1") {
          setRescheduleDetails(response.data.data);
        } else {
          alert("Something went wrong");
        }
      } else {
        alert("Something went wrong");
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  const savereschedule = () => {
    const formData = new FormData();
    formData.append("order_id", bookingid);
    formData.append("order_date", rescheduleDate);
    formData.append("app_notes", reschedunotes);
    const staffIds = [];
    for (let i = 0; i < reschedulestaff.length; i++) {
      staffIds.push(reschedulestaff[i].value);
    }
    formData.append("staff_ids", staffIds);

    saverescheduledata(formData).then((response) => {
      if (response.code === 200) {
        if (response.data.status === "1") {
          alert("Booking reschedule successfully");
          handleClose5();
          rescheduleData(bookingid);
        } else {
          alert("Something went wrong");
        }
      } else {
        alert("Something went wrong");
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  useEffect(() => {
    getservices();
  }, [edituser]);

  const [service, setService] = useState([]);
  const [subService, setSubService] = useState([]);
  const [addons, setAddons] = React.useState([]);

  useEffect(() => {
    const serviceData = service.map((item) => {
      return {
        ...item.subservices,
      }
    });
    setSubService(serviceData);

    const serviceaddon = service.map((item) => {
      return {
        ...item.addons,
      };
    }
    );
    setAddons(serviceaddon);

  }, [edituser]);

  const getservices = () => {
    const formData = new FormData();
    formData.append("user_id", edituser.id);
    getbookingservicesApi(formData).then((res) => {
      if (res.code === 200) {
        const apiResponse = res.data;
        const itemIdsToRemove = [1, 2, 18, 19, 20, 21, 22, 23];

        // Iterate over each service
        apiResponse.data.forEach(service => {
          // Filter out subservices with specified item_ids
          service.subservices = service.subservices.filter(subservice => {
            return !itemIdsToRemove.includes(parseInt(subservice.id));
          });
        });
        setService(apiResponse.data);
      } else {
        alert("Something went wrong");
      }
    });
  };


  const changeCounter2 = (value, id, serviceid) => {

    const updatedArray = service.map((item) => {
      if (item.id === serviceid) {
        const subservices = item.subservices.map((subservice) => {
          if (subservice.id === id) {
            if (value === "increment") {
              return {
                ...subservice,
                quantity: parseInt(subservice.quantity) + 1,
              };
            } else if (value === "decrement") {
              return {
                ...subservice,
                quantity: parseInt(subservice.quantity) - 1,
              };
            }
          }
          return subservice;
        }
        );
        return {
          ...item,
          subservices: subservices,
        };

      }

      return item;
    });

    setService(updatedArray);

  };



  const changeAddon = (value, id, serviceid) => {
    const updatedArray = service.map((item) => {
      if (item.id === serviceid) {
        const addons = item.addons.map((addon) => {
          if (addon.id === id) {
            if (value === "increment") {
              return {
                ...addon,
                quantity: parseInt(addon.quantity) + 1,
              };
            } else if (value === "decrement") {
              return {
                ...addon,
                quantity: parseInt(addon.quantity) - 1,
              };
            }
          }
          return addon;
        }
        );
        return {
          ...item,
          addons: addons,
        };

      }

      return item;
    });

    setService(updatedArray);
  };

  const updateservicepreference = () => {
    const formData = new FormData();
    formData.append("user_id", edituser.id);
    formData.append("service_preference", JSON.stringify(service));
    updateservicepreferenceApi(formData).then((res) => {
      if (res.code === 200) {
        const apiResponse = res.data;
        if (apiResponse.status === "1") {
          alert("Service preference updated successfully");
          getservices();
          handleClose();
        } else {
          alert("Something went wrong");
        }
      } else {
        alert("Something went wrong");
      }
    });
  };


  const [leftsection, setLeftsection] = useState('col-md-8');

  const [rightsection, setRightsection] = useState('col-md-4');

  const manageView = () => {
    if (leftsection === 'col-md-8') {
      setLeftsection('col-md-4');
      setRightsection('col-md-8');
    }
    else {
      setLeftsection('col-md-8');
      setRightsection('col-md-4');
    }
  }

  //invoice loader state
  const [invoiceLoader, setInvoiceLoader] = useState(false);
  const [invoiceprice, setInvoiceprice] = useState();
  const [invoicenotes, setInvoicenotes] = useState('');
  const [invoiceservice, setInvoiceservice] = useState([]);

  const [paymentMethodId, setPaymentMethodId] = useState('');
  const [invoiceId, setInvoiceId] = useState('');
  const [invoicetotal, setInvoicetotal] = useState('');

  const [serviceTitle, setServiceTitle] = useState('');
  const [serviceStaxId, setServiceStaxId] = useState('');
  const [bookingPriceTest, setBookingPriceTest] = useState(0);
  const [bookingDiscount, setBookingDiscount] = useState(0);
  const [bookingNotes, setBookingNotes] = useState('');
  const [bookingTimeNote, setBookingTimeNote] = useState('');

  const [cardNumber, setCardNumber] = useState('');
  const [cardExpDate, setCardExpDate] = useState('');
  const [cardCVV, setCardCVV] = useState('');
  const [fullName, setFullName] = useState('');
  const [addCardValidate, setAddCardValidate] = useState(false)
  const { getExpiryDateProps } = usePaymentInputs();
  const [orderId, setorderId] = useState('');

  const handleAddCard = () => {
    if (cardNumber == '' || cardNumber.length != 16 || fullName == '' || cardCVV == '' || cardCVV.length != 3 || cardExpDate == '') {
      setAddCardValidate(true)
    } else {
      //check fullName like sanjay kumar 
      const name = fullName.split(" ");
      if (name.length < 2) {
        alert("Please enter full name");
        return false;
      }

      const formData = new FormData();

      formData.append("customer_id", edituser.customer_id);
      formData.append("card_number", cardNumber);
      formData.append("person_name", fullName);
      formData.append("card_cvv", cardCVV);
      formData.append("card_exp", cardExpDate);

      createPaymentMethod(formData).then((res) => {
        if (res.code === 200) {
          alert("Card Added Successfully");
          handleClose6();
        }
        else {
          alert("Something went wrong");
        }
      }).catch((err) => {
        console.log("err", err);
      });
    }
  }


  const [show6, setShow6] = useState(false);

  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  const [show9, setShow9] = useState(false);

  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);

  const [showModal7, setShowModal] = useState(false);

  const handleClose7 = () => setShowModal(false);
  const handleShow7 = () => setShowModal(true);

  const [selectedCard, setSelectedCard] = useState('');
  const [paymentMethod, setPaymentMethod] = useState([]);



  const createStaxId = () => {
    setInvoiceLoader(true);
    const formData = new FormData();
    formData.append("client_name", edituser.first_name + " " + edituser.last_name);
    formData.append("client_id", edituser.id);
    formData.append("client_phone", edituser.phone);
    formData.append("client_address", edituser.address);
    formData.append("city", edituser.city);
    formData.append("state", edituser.state);
    formData.append("zip", edituser.zip);
    formData.append("clientemail", edituser.user_email);

    createStaxIdApi(formData).then((response) => {
      if (response.code === 200) {
        if (response.data.status === "1") {
          NotificationManager.success("Stax Id created successfully");
          handleClose();
        } else {
          NotificationManager.error(response.data.message);
        }
      } else {
        alert("Something went wrong");
      }

    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      setInvoiceLoader(false);
    });
  }


  const getsubserviceaddons = (order_id) => {
    const formData = new FormData();
    formData.append("order_id", order_id);
    getsubserviceaddonsApi(formData).then((response) => {
      if (response.code === 200) {
        if (response.data.status === "1") {
          setInvoiceservice(response.data.data);

          // handleShow4();
        } else {
          alert("Something went wrong");
        }
      } else {
        alert("Something went wrong");
      }

    }).catch((error) => {
      console.log(error);
    });
  }

  // const createinvoice = () => {

  //   if (invoicenotes == '') {
  //     alert("Please enter invoice notes");
  //     return false;
  //   }

  //   console.log("invoiceservice:", JSON.stringify(invoiceservice.subservices));
  //   console.log(typeof (invoiceservice));
  //   const formData = new FormData();
  //   formData.append("customer_id", edituser.customer_id);
  //   formData.append("total", invoiceprice);
  //   formData.append("subtotal", invoiceprice);
  //   formData.append("notes", invoicenotes);
  //   formData.append("order_id", orderId);

  //   const lineItems = [];

  //   for (let i = 0; i < invoiceservice.subservices.length; i++) {
  //     const data = {
  //       "id": invoiceservice.subservices[i].subservice_id,
  //       "item": invoiceservice.subservices[i].units_title,
  //       "details": invoiceservice.subservices[i].units_title,
  //       "quantity": invoiceservice.subservices[i].quantity,
  //       "price": invoiceservice.subservices[i].base_price
  //     }
  //     lineItems.push(data);
  //   }

  //   for (let i = 0; i < invoiceservice.addons.length; i++) {
  //     const data = {
  //       "id": invoiceservice.addons[i].id,
  //       "item": invoiceservice.addons[i].addon_service_name,
  //       "details": invoiceservice.addons[i].addon_service_name,
  //       "quantity": invoiceservice.addons[i].addons_service_qty,
  //       "price": invoiceservice.addons[i].base_price
  //     }
  //     lineItems.push(data);
  //   }

  //   formData.append("line_item", JSON.stringify(lineItems));

  //   createinvoiceApi(formData).then((response) => {
  //     if (response.code === 200) {
  //       if (response.data.status === "1") {
  //         NotificationManager.success("Invoice created successfully");
  //         getinvoice(orderId);
  //       } else {
  //         NotificationManager.error(response.data.message);
  //       }
  //     } else {
  //       alert("Something went wrong");
  //     }

  //   }).catch((error) => {
  //     console.log(error);
  //   });
  // }

  let abortController = new AbortController();


  const getservicesdata = (order_id) => {
    const formData = new FormData();
    formData.append("order_id", order_id);
    getservicesdataApi(formData).then((response) => {
      if (response.code === 200) {
        if (response.data.status === "success") {
          setInvoiceprice(response.data.data.amount);
          setInvoicenotes(response.data.data.app_notes)
          setServiceTitle(response.data.data.service_title)
          setServiceStaxId(response.data.data.service_stax_id)
          setBookingPriceTest(response.data.data.booking_price_test)
          setBookingDiscount(response.data.data.booking_discount)
          setBookingNotes(response.data.data.booking_notes)
          setBookingTimeNote(response.data.data.booking_time_note)
          setBookingtimesheetcleaningtime(response.data.data.booking_timesheet_cleaning_time);
          handleShow4();
        } else {
          alert("Something went wrong");
        }
      } else {
        alert("Something went wrong");
      }

    }).catch((error) => {
      console.log(error);
    });
  }

  const createinvoice = () => {
    abortController.abort();
    if (invoicenotes == '') {
      alert("Please enter invoice notes");
      return false;
    }

    const formData = new FormData();
    var totalprice = 0;

    const lineItems = [];


    for (let i = 0; i < rows.length; i++) {
      const serviceParts = rows[i].service.split(' ');
      const data = {
        "id": serviceParts[0],
        "item": serviceParts.slice(1).join(' '),
        "details": rows[i].notes,
        "quantity": rows[i].quantity,
        "price": parseFloat(rows[i].price)
      }
      lineItems.push(data);

      // Calculate the total price for this item and add it to the totalprice
      totalprice += data.quantity * data.price;
    }

    formData.append("customer_id", edituser.customer_id);
    formData.append("total", invoiceprice);
    formData.append("subtotal", invoiceprice);
    formData.append("notes", invoicenotes);
    formData.append("order_id", orderId);

    formData.append("line_item", JSON.stringify(lineItems));

    createinvoiceApi(formData).then((response) => {
      if (response.code === 200) {
        if (response.data.status === "1") {
          NotificationManager.success("Invoice created successfully");
          getinvoice(orderId);
        } else {
          NotificationManager.error(response.data.message);
        }
      } else {
        alert("Something went wrong");
      }

    }).catch((error) => {
      console.log(error);
    });

  }

  const [invoiceList, setInvoiceList] = useState([]);


  const getinvoice = (orderId) => {
    abortController = new AbortController(); // Initialize a new controller for each call
    const signal = abortController.signal;
    // setLoading(true);
    setInvoiceLoader(true);
    getinvoiceApi(orderId).then((response) => {
      if (response.code === 200) {
        if (response.data.status === "1") {
          setInvoiceList(response.data.data);
        } else {
          setInvoiceList([]);
          NotificationManager.info("No invoice found");
        }
      } else {
        alert("Something went wrong");
      }

    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      // setLoading(false);
      setInvoiceLoader(false);
    });
  }

  const invoicecolumns = [{
    dataField: 'id',
    text: 'Id',
    formatter: (cell, row, rowIndex, formatExtraData) => (
      <div>{rowIndex + 1}</div>
    ),
    sort: true
  }, {
    dataField: 'id',
    text: 'INVOICE ID',
    sort: true
  },
  {
    dataField: 'created_at',
    text: 'INVOICE DATE',
    sort: true,
    formatter: (cell, row) => {
      const date = row.created_at;
      const newDate = new Date(date);
      var day = newDate.getDate();
      day = day < 10 ? '0' + day : day;
      const month = newDate.toLocaleString('default', { month: 'short' });
      const year = newDate.getFullYear();
      var hours = newDate.getHours();
      hours = hours < 10 ? '0' + hours : hours;
      var minutes = newDate.getMinutes();
      minutes = minutes < 10 ? '0' + minutes : minutes;
      const ampm = hours >= 12 ? 'PM' : 'AM';
      const formattedDate = `${day} ${month} ${year} ${hours}:${minutes} ${ampm}`;
      return (
        <div>{formattedDate}</div>
      )
    }
  },
  {
    dataField: 'total',
    text: 'AMOUNT',
    sort: true
  },
  {
    text: 'STATUS',
    isDummy: true,
    formatter: (cell, row) => {
      const status = row.status;
      if (status === "DRAFT" || status === "PENDING") {
        return (
          <Button className="Enable-Active-bttn invoice-list-btn" onClick={(id) => getpaymentCards(row.id, row.total)}>PAY Now</Button>
        )
      } else {
        return (
          status
        )
      }
    }
  },
  {
    text: 'ACTION',
    isDummy: true,
    formatter: (cell, row) => {
      const status = row.status;
      if (status === "PAID") {
        return (
          "PAID"
        )
      } else {
        return (
          <div className="button-group">
            <Button className="Delete-Active-bttn me-2"><RiDeleteBin6Line /></Button>
          </div>
        )
      }
    }
  }];


  const getpaymentCards = (id, total) => {
    setShowModal(true);
    setInvoiceId(id);
    setInvoicetotal(total);
    const formData = new FormData();
    formData.append("customer_id", edituser.customer_id);

    getpaymentMethod(formData).then((res) => {
      if (res.code == 200) {
        if (res.data.status === "1") {
          const paymentMethod = res.data.data;
          setPaymentMethod(paymentMethod);
          if (paymentMethod && paymentMethod.length != 0 && !selectedCard) {
            setSelectedCard(`cardOption0`);
            setPaymentMethodId(paymentMethod[0].id);
          }
        } else {
          alert("Please Add card first");
          handleClose7();
        }
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  const payinvoice = () => {
    const formData = new FormData();
    formData.append("payment_method_id", paymentMethodId);
    formData.append("apply_balance", invoicetotal);
    formData.append("invoiceId", invoiceId);

    payinvoiceApi(formData).then((response) => {
      if (response.code === 200) {
        if (response.data.status === "1") {
          alert("YOUR INVOICE" + " " + response.data.data.status);
          getinvoice(orderId);
          handleClose7();
        } else {
          alert("Something went wrong");
        }
      } else {
        alert("Something went wrong");
      }

    }
    ).catch((error) => {
      console.log(error);
    }
    );
  }

  const [preferredStaff, setPreferredStaff] = useState([]);

  const getpreferredstaff = () => {
    getpreferredstaffApi().then((response) => {
      if (response.code === 200) {
        if (response.data.status === "1") {
          setPreferredStaff(response.data.data);
        } else {
          alert("data not found");
        }
      } else {
        alert("Something went wrong");
      }

    }
    ).catch((error) => {
      console.log(error);
    }
    );
  }

  useEffect(() => {
    getpreferredstaff();
  }, [edituser]);



  const copy = async (data) => {
    try {
      await navigator.clipboard.writeText(data);
      alert("Text copied to clipboard");
    } catch (error) {
      console.error('Unable to copy text to clipboard:', error);
    }
  }


  const [cardView, setcardView] = useState("none");

  const [tableView, settableView] = useState("block");

  const [tapname, setTapname] = useState("Table");

  const toggleStyle = () => {
    if (cardView === "block") {
      setcardView("none");
      settableView("block");
      setTapname("Card");
    } else {
      setcardView("block");
      settableView("none");
      setTapname("Table");
    }
  }


  const statusFormatter = (cell, row) => {
    var book_by = row.book_by; // Assuming `book_by` is a property in `row`
    var bookingstatus = row.status; // Assuming `bookingstatus` is a property in `row`

    if (book_by === "0") {
      book_by = "Admin";
    } else if (book_by === "1") {
      book_by = "Client";
    } else if (book_by === "2") {
      book_by = "Technician";
    }

    if (bookingstatus === "A") {
      return "Active (unconfirmed booked by " + book_by + ")";
    } else if (bookingstatus === "C") {
      return "Confirmed (confirmed by admin)";
    } else if (bookingstatus === "R") {
      return "Reject";
    } else if (bookingstatus === "CC") {
      return "Cancel by Client";
    } else if (bookingstatus === "CS") {
      return "Cancel by service provider";
    } else if (bookingstatus === "CO") {
      return "Completed";
    } else if (bookingstatus === "MN") {
      return "Mark as no show";
    } else if (bookingstatus === "UC") {
      return "Confirmed by Client";
    } else {
      return "";
    }
  };

  const [bookingidcrm, setBookingIdCrm] = useState(0);
  const [oldbookingid, setOldBookingId] = useState(0);

  const callChildFunction = (booking_id) => {
    setBookingIdCrm(booking_id);
  };



  const optionFormatter22 = (cell, row) => {

    if (row.status === "A" && row.status !== "") {
      return (
        <>
          {/* <button
            className="Edit-Active-bttn btn btn-primary me-2"
            onClick={() => {
              getEditbooking(row.order_id);
            }}
          >
            <FaRegEdit />
          </button> */}

          <button
            className="confirm-Active me-2"
            onClick={() => {
              callChildFunction(row.order_id);
            }}
          >
            <FaEye />
          </button>

          <button className="confirm-Active me-2" onClick={() => confirmbooking(row.order_id)}>
            <FaCheck />
          </button>
          <button className="Delete-Active-bttn me-2" onClick={() => deletebooking(row.order_id)}>
            <RiDeleteBin6Line />
          </button>
          {row.recurring_id !== 0 && row.recurring_id != null && row.recurring_id !== ""
            ? (
              <Button className="Delete-Active-bttn" onClick={() => deletereccuringbooking(row.recurring_id, row.order_date)}>Delete all recurring</Button>
            ) : (
              // <Button className="Delete-Active-bttn" onClick={deletereccuringbooking} disabled> <RiDeleteBin6Line /> Delete all recurring </Button>
              ""
            )}
        </>
      );
    }

    if (row.status === "CO" || row.status == "UC" && row.status !== "") {
      return (
        <>
          {/* <button
            className="Edit-Active-bttn btn btn-primary me-2"
            onClick={() => {
              getEditbooking(row.order_id);
            }}
          >
            <FaRegEdit />
          </button> */}

          <button
            className="confirm-Active me-2"
            onClick={() => {
              callChildFunction(row.order_id);
            }}
          >
            <FaEye />
          </button>

          <Button className="Delete-Active-bttn me-2" onClick={() => uncomplatebooking(row.order_id)}>
            <FaTimesCircle />
          </Button>

          {edituser.customer_id !== 0 && edituser.customer_id !== null && edituser.customer_id !== "" ? (
            <Button className="pay-Active-bttn me-2" onClick={(e) => {
              getsubserviceaddons(row.order_id);
              getinvoice(row.order_id);
              setorderId(row.order_id);
              getservicesdata(row.order_id);
            }}>
              <FaMoneyBillAlt />
            </Button>
          ) : (
            <Button className="pay-Active-bttn" onClick={createStaxId}>
              <FaMoneyBillAlt /> Create Stax Id
            </Button>
          )}
        </>
      );
    }

    if (row.status === "C" && row.status !== "") {
      return (
        <>
          {/* <button
            className="Edit-Active-bttn btn btn-primary me-2"
            onClick={() => {
              getEditbooking(row.order_id);
            }}
          >
            <FaRegEdit />
          </button> */}

          <button
            className="confirm-Active me-2"
            onClick={() => {
              callChildFunction(row.order_id);
            }}
          >
            <FaEye />
          </button>

          {edituser.customer_id !== 0 && edituser.customer_id !== null && edituser.customer_id !== "" ? (
            <Button className="pay-Active-bttn me-2" onClick={(e) => {
              getsubserviceaddons(row.order_id);
              getinvoice(row.order_id);
              setorderId(row.order_id);
              getservicesdata(row.order_id);
            }}>
              <FaMoneyBillAlt />
            </Button>
          ) : (
            <Button className="pay-Active-bttn" onClick={createStaxId}>
              <FaMoneyBillAlt /> Create Stax Id
            </Button>
          )}

          {/* <Button className="confirm-Active-bttn me-2" onClick={() => complatebooking(row.order_id)}>
            <FaCheckCircle />
          </Button> */}

          <Button className="unconfirm-Active-bttn me-2" onClick={() => unconfirmbooking(row.order_id)}>
            <FaRegTimesCircle />
          </Button>

          <Button className="Delete-Active-bttn" onClick={() => deletebooking(row.order_id)}>
            <RiDeleteBin6Line />
          </Button>
        </>
      );
    }

    if (row.status === "R" && row.status !== "") {
      return (
        <>
          {/* <button
            className="Edit-Active-bttn btn btn-primary me-2"
            onClick={() => {
              getEditbooking(row.order_id);
            }}
          >
            <FaRegEdit />
          </button> */}

          <button
            className="confirm-Active me-2"
            onClick={() => {
              callChildFunction(row.order_id);
            }}
          >
            <FaEye />
          </button>


          <Button className="confirm-Active-bttn" onClick={() => activebooking(row.order_id)}>
            <FaToggleOn />
          </Button>
        </>
      );
    }

    if (row.status === "CC" && row.status !== "") {
      return (
        <>
          {/* <button className="Edit-Active-bttn btn btn-primary me-2"
            onClick={() => {
              getEditbooking(row.order_id);
            }}
          >
            <FaRegEdit />
          </button> */}

          <button
            className="confirm-Active me-2"
            onClick={() => {
              callChildFunction(row.order_id);
            }}
          >
            <FaEye />
          </button>
        </>
      );
    }

    if (row.status === "Q" && row.status !== "") {
      return (
        <>
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
          >
            <Button className="confirm-Active me-2" onClick={() => activequotebooking(row.order_id)}>
              <MdAddTask />
            </Button>

          </OverlayTrigger>

          <button className="Delete-Active-bttn me-2" onClick={() => deletebooking(row.order_id)}>
            <RiDeleteBin6Line />
          </button>
        </>
      );
    }

    // Return null if none of the conditions match
    return null;

  }

  const renderTooltip2 = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Add to booking
    </Tooltip>
  );


  const columns2 = [{
    dataField: 'fullname',
    text: 'TECHNICIAN NAME'
  }, {
    dataField: 'booking_date_time',
    text: 'START DATE',
    sort: true
  },
  {
    dataField: 'title',
    text: 'SERVICE',
    sort: true
  },
  {
    dataField: 'status',
    text: 'STATUS',
    sort: true,
    formatter: statusFormatter
  },
  {
    text: 'ACTION',
    isDummy: true,
    formatter: optionFormatter22
  }];


  const rowStyle = (row, index) => {
    let color = "";
    let my_cls = "";

    switch (row.status) {
      case 'A':
      case 'R':
      case 'CS':
      case 'MN':
        color = "#FFFF00";
        break;
      case 'CC':
        color = "#FF8C00";
        break;
      case 'C':
        color = "#008000";
        break;
      case 'UC':
        color = "#800080";
        row.status = 'C';
        break;
      case 'CO':
        color = "#808080";
        break;
      case 'Q':
        color = "#ffc0cb";
        break;
      default:
        // You can set a default color here if needed
        break;
    }

    return {
      backgroundColor: color,
    };
  };


  const [editbookingtimeEnd, setEditbookingtimeEnd] = useState('');

  useEffect(() => {
    getCalculatedEndTime(editbooking.start_time, expedtedendtime);
  }, [editbooking.start_time, expedtedendtime]);


  function getCalculatedEndTime(starttime, endtimeInMinutes) {
    const startMoment = moment(starttime, 'hh:mm A');
    const endMoment = startMoment.clone().add(endtimeInMinutes, 'minutes');

    // Format the result as "HH:mm"
    const formattedEndTime = endMoment.format('HH:mm');

    // return formattedEndTime;
    setEditbookingtimeEnd(formattedEndTime);
  }

  const handleStartTimeChange = (e) => {
    const newStartTime = e.target.value;
    setEditbookingStartTime(newStartTime);

    const startMoment = moment(newStartTime, 'hh:mm A');
    const endMoment = startMoment.clone().add(expedtedendtime, 'minutes');

    const formattedEndTime = endMoment.format('HH:mm');
    setEditbookingtimeEnd(formattedEndTime);
  }

  const handleInputChange = (e) => {
    //trim space
    const value = e.trim();
    setSearchkeyword(value);
  }

  const getusershippingaddressdata = (shippingdata) => {
    if (shippingdata.length > 0 && shippingdata[0].value) {
      setDefault_shipping_address_id(shippingdata[0].value);
      const formData = new FormData();
      formData.append("id", shippingdata[0].value);
      getusershippingaddressbyid(formData).then((res) => {
        if (res.code === 200) {
          const apiResponse = res.data;
          // setAppointmentcity(apiResponse.data.shipping_city);
          setEdituser(prevState => ({
            ...prevState,
            shipping_address: apiResponse.data.shipping_address,
            shipping_city: apiResponse.data.shipping_city,
            shipping_state: apiResponse.data.shipping_state,
            shipping_zip: apiResponse.data.shipping_zip,
          }));
          setshippingstate(apiResponse.data.shipping_state);
          setSelectedOption([{
            id: shippingdata[0].value, // Keep consistent with the structure of your options
            label: `${apiResponse.data.address_title} - ${apiResponse.data.shipping_address}, ${apiResponse.data.shipping_city}, ${apiResponse.data.shipping_state} ${apiResponse.data.shipping_zip}`,
            // Include other properties as necessary
          }]);
        } else {
          alert("Something went wrong");
        }
      });
    } else {
      setSelectedOption([]);
    }
  }

  const deleteusershippingaddress = (shippingdataid) => {
    if (window.confirm("Are you sure you want to delete this address?")) {
      const formData = new FormData();
      formData.append("id", shippingdataid);
      deleteusershippingaddressApi(formData).then((res) => {
        if (res.code === 200) {
          const apiResponse = res.data;
          getusershipppingaddress(props.id, Searchkeyword);
        } else {
          alert("Something went wrong");
        }
      });
    }
  }


  const [show8, setShow8] = useState(false);

  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  const [newshippingaddress, setNewshippingaddress] = useState("");
  const [newshippingcity, setNewshippingcity] = useState("");
  const [newshippingstate, setNewshippingstate] = useState("");
  const [newshippingzip, setNewshippingzip] = useState("");
  const [addresstitle, setAddresstitle] = useState("");

  const saveusershippingaddress = () => {

    if (newshippingaddress == "") {
      alert("Please enter address");
      return;
    }
    if (newshippingcity == "") {
      alert("Please enter city");
      return;
    }
    if (newshippingstate == "") {
      alert("Please enter state");
      return;
    }
    if (newshippingzip == "") {
      alert("Please enter zip");
      return;
    }

    if (addresstitle == "add") {
      setAddresstitle("update");
    }

    const formData = new FormData();
    formData.append("user_id", props.id);
    formData.append("shipping_address", newshippingaddress);
    formData.append("address_title", addresstitle);
    formData.append("shipping_city", newshippingcity);
    formData.append("shipping_state", newshippingstate);
    formData.append("shipping_zip", newshippingzip);
    addusershippingaddress(formData).then((res) => {
      if (res.code === 200) {
        const apiResponse = res.data;
        if (apiResponse.status == 1) {
          getusershipppingaddress(props.id, Searchkeyword);
          handleClose8();
        } else {
          alert("Something went wrong");
        }
      } else {
        alert("Something went wrong");
      }
    });
  }

  const [addClasss, setAddClasss] = useState(false);

  const handleDelete = () => {
    // if (window.confirm("Are you sure you want to delete this booking?")) {
    const formData = new FormData();
    formData.append("client_id", edituser.id);
    deleteUser(formData).then((response) => {
      if (response.code === 200) {
        const apiResponse = response.data;
        if (apiResponse.status == "success") {
          // setDelcount(prevCount => prevCount + 1); 
          window.location.reload();
          // handleClose();
        } else {
          alert("user delete failed");
        }
      }
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      props.onCloseidChange(0);
    })
    // }
  }

  const sendemailpassword = () => {
    const formData = new FormData();
    formData.append("client_id", edituser.id);
    sendemailpasswordapi(formData).then((response) => {
      if (response.code === 200) {
        const apiResponse = response.data;
        if (apiResponse.status == "1") {
          alert("Password sent to email");
        } else {
          alert("user delete failed");
        }
      }
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      props.onCloseidChange(0);
    })
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Send Password to Email
    </Tooltip>
  );

  const [bookingtimesheetcleaningtime, setBookingtimesheetcleaningtime] = useState();

  const [rows, setRows] = useState([
    {
      service: '',
      notes: '',
      quantity: 1,
      price: 0,
    },
  ]);

  useEffect(() => {
    if (Array.isArray(bookingtimesheetcleaningtime)) {
      // Map the services according to your conditions
      const serviceMapping = {
        "House Cleaning": "Scheduled Cleaning",
        "First Time General Cleaning": "First Time Cleaning",
        "First Time Deluxe Cleaning": "First Time Cleaning",
        "Move In/Out Service": "TBD",
        "Window Cleaning": "Extra Service",
        "Area Fee": "Area Fee"
      };

      const updatedRows = bookingtimesheetcleaningtime.map((staff, index) => {
        const cleanedServiceTitle = serviceTitle.replace(':', '').trim();

        const service = serviceMapping[cleanedServiceTitle];

        const rowobject = {
          service: serviceStaxId,
          notes: staff.fullname,
          quantity: convertToHM(staff.time_difference),
          price: 65,
          total: 65 * convertToHM(staff.time_difference),
        }
        return rowobject;
      });

      const rowobject2 = {
        // service: 'e92b0e79-55b6-4920-a7fa-16641bd2ee0e',
        service: serviceStaxId,
        notes: 'Base Fee',
        quantity: 1,
        price: 30,
        total: 30
      }
      const updatedRowsWithRowObject2 = [...updatedRows, rowobject2];
      setRows(updatedRowsWithRowObject2);
    } else {
      const services = [{
        service: '',
        notes: '',
        quantity: 1,
        price: 0,
      }];
      setRows(services);
    }
  }, [bookingtimesheetcleaningtime]);

  const addRow = () => {
    setRows([...rows, {
      service: '',
      notes: '',
      quantity: 0,
      price: 0,
    }]);
  };

  const updateRow = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };

  const removeRow = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
  };

  const [discountinvoice, setDiscountinoice] = useState(0);


  const [productlist, setproductlist] = useState([]);

  const productlistdata = () => {
    getproductlistdataApi().then((res) => {
      if (res.code === 200) {
        const apiResponse = res.data.data;
        setproductlist(apiResponse);
      } else {
        alert("Something went wrong");
      }
    });
  };


  const calculateTotal = (rows, discount) => {
    let total = rows.reduce((acc, row) => acc + row.total, 0);
    total -= parseFloat(discount) || 0;
    // setcalculateTotal(total);
    return total;
  };

  const [keywords, setKeywords] = useState('');

  const handleChange = (e) => {
    setKeywords(e.target.value);
  }

  const getdefaultPaymentMethod = () => {
    const formData = new FormData();
    formData.append("user_id", edituser.id);

    getdefaultpayment(formData).then((res) => {
      if (res.code === 200) {
        if (res.data.status == '1') {
          const apiResponse = res.data;
          setDefaultpaymentmethod(apiResponse.data.payment_id);
        }
        else {
          console.log("Something went wrong");
        }

      } else {
        alert("Something went wrong");
      }
    });
  }

  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [showuniqueemailerror, setShowuniqueemailerror] = useState(false);

  const togglePopupVisibility = () => {
    setIsPopupVisible(!isPopupVisible);
    setShowuniqueemailerror(false);
  };



  const updateemaill = (e) => {
    setIsPopupVisible(false);
    const formData = new FormData();
    formData.append("client_id", edituser.id);
    formData.append("user_email", updateemail);

    updateUserEmail(formData).then((response) => {
      if (response.code === 200) {
        if (response.data.status === "success") {
          alert("Email updated successfully");
          setEdituser(prevState => ({
            ...prevState,
            user_email: updateemail
          }));
          setIsPopupVisible(false);
        } else {
          setShowuniqueemailerror(true);
          setIsPopupVisible(true);
        }
      } else {
        setIsPopupVisible(false);
      }
    }).catch((error) => {
      setIsPopupVisible(false);
    });
  }

  return (
    <>

      {/* {
        loading ? (
          <div className="loading">
            <Roller />
          </div>
        ) : (
          <> */}
      {/* EDIT BOOKING MODEL START HERE */}
      <Modal show={show3} onHide={handleClose3} className="Sidebar-Modal-end">
        <Modal.Header closeButton>
          <Modal.Title>Edit Booking Details3</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            loading ? (
              <div className="loading">
                <Roller />
              </div>
            ) : (
              <>
                <form id="edit-booking-form">
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Service : {editbooking.service_name}</Form.Label>
                      </Form.Group>
                    </div>
                    <div className="col-md-6" style={{ display: "none" }}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Method : Home Size</Form.Label>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row" style={{ display: "none" }}>
                    <div className="col-md-6">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Billed Amount : </Form.Label>
                        <div className="input-group">
                          <span className="input-group-text">$</span>
                          <input
                            type="number"
                            placeholder="0"
                            className="form-control"
                            disabled
                            value={editbooking.billed_amount || totalAmount || 0}
                            name="billed_amount"
                          />
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Price : </Form.Label>
                        <div className="input-group">
                          <span className="input-group-text">$</span>
                          <input
                            type="text"
                            className="form-control"
                            value={editbooking.billed_amount}
                            name="billed_amount"
                            onChange={handleEditInputChange3}
                          />
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row" style={{ display: "none" }}>
                    <div className="col-md-12">
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Duration : </Form.Label>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <div className="input-group mb-3">
                        <span className="input-group-text">
                          <BsStopwatch />
                        </span>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="10"
                          disabled
                          value={editbooking.hours}
                        />
                        <span className="input-group-text">Hours</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-group mb-3">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="30"
                          disabled
                          value={editbooking.mints}
                        />
                        <span className="input-group-text">Minutes</span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="Service-details-box-inner-title">
                        <h5>Sub Services</h5>
                      </div>
                    </div>
                    {EditSubServices.map((subservice, index) => {
                      return (
                        <div className="col-md-12">
                          <div className="Service-details-box-inner extra-service mb-3">
                            <div className="left-side">
                              <div className="d-flex align-items-center">
                                <h3 className="Service-details-box-inner-title">
                                  {subservice.units_title}
                                </h3>
                              </div>
                            </div>
                            <div className="right-side">
                              <div className="d-flex align-items-center">
                                <div className="extra-service-icon">
                                  <Button
                                    href="#"
                                    className="add-item-btn"
                                    disabled={subservice.quantity == 1 ? true : false}
                                    onClick={(e) => {
                                      setEditstart(true);
                                      changeCounter3("decrement", subservice.id);
                                    }
                                    }
                                  >
                                    -
                                  </Button>
                                </div>
                                <div className="extra-service-value">
                                  {subservice.quantity}
                                </div>
                                <div className="extra-service-icon">
                                  <Button
                                    href="#"
                                    className="add-item-btn"
                                    disabled={subservice.quantity == 6 ? true : false}
                                    onClick={(e) => {
                                      setEditstart(true);
                                      changeCounter3("increment", subservice.id);
                                      // calculatePrice(e);
                                      //setChooseSubService(subservice.id);
                                    }
                                    }
                                  >
                                    +
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="Service-details-box-inner-title">
                        <h5>Addons</h5>
                      </div>
                    </div>
                    {editaddons.map((value, index) => {
                      return (
                        <div className="col-md-12">
                          <div className="Service-details-box-inner extra-service mb-3">
                            <div className="left-side">
                              <div className="d-flex align-items-center">
                                <h3 className="Service-details-box-inner-title">
                                  {value.addon_service_name}
                                </h3>
                              </div>
                            </div>
                            <div className="right-side">
                              <div className="d-flex align-items-center">
                                <div className="extra-service-icon">
                                  <Button
                                    href="#"
                                    className="add-item-btn"
                                    disabled={value.addons_service_qty == 0 ? true : false}
                                    onClick={(e) => {
                                      setEditstart(true);
                                      changeeditaddons("decrement", value.id);
                                    }
                                    }
                                  >
                                    -
                                  </Button>
                                </div>
                                <div className="extra-service-value">
                                  {value.addons_service_qty}
                                </div>
                                <div className="extra-service-icon">
                                  <Button
                                    href="#"
                                    className="add-item-btn"
                                    disabled={value.addons_service_qty == 6 ? true : false}
                                    onClick={(e) => {
                                      setEditstart(true);
                                      changeeditaddons("increment", value.id);
                                    }
                                    }
                                  >
                                    +
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="row">
                    <div className="estimated-bottom">
                      <div className="estimated-head">
                        <h5>Estimated Price && Duration</h5>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Change Billed Amount</Form.Label>
                            <div className="input-group">
                              <span className="input-group-text">$</span>
                              <Form.Control
                                type="text"
                                placeholder=""
                                value={Math.round(editbookingestimatedPrice)}
                                onChange={(e) => {
                                  handlechageeditamount(e.target.value);
                                }
                                }
                              />
                            </div>
                          </Form.Group>
                        </div>
                        <div className="col-md-12">
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Change Billed Hours</Form.Label>
                            <InputMask
                              mask="99:99"
                              maskChar=""
                              alwaysShowMask
                              formatChars={{ '9': '[0-9]', ':': ':' }}
                              placeholder="HH:mm"
                              className="form-control"
                              value={convertToHHMM(editbookingestimatedDuration)}
                              onChange={
                                (e) => {
                                  handlechageedittime(e);
                                }
                              }
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Estimated Billed Amount</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              value={("$" + edittotalAmount + " to " + "$" + Math.round(editbookingestimatedPrice)) || ""}
                              disabled
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-12">
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Estimated Billed Hours==</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              disabled
                              value={(convertToHHMM(editbaseTime) + " to " + convertToHHMM(editbookingestimatedDuration)) || ""}
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Client Name: </Form.Label>
                        <Form.Control type="text" placeholder="Enter Client Name" value={editbooking.client_name} name="client_name" onChange={handleEditInputChange3} />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Client Email: </Form.Label>
                        <Form.Control type="email" placeholder="Enter Client Email" value={editbooking.client_email} name="client_email" onChange={handleEditInputChange3} />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Client Address: </Form.Label>
                        <Form.Control
                          type="text"
                          as="textarea"
                          aria-label="With textarea"
                          value={editbooking.client_address}
                          name="client_address"
                          onChange={handleEditInputChange3}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Appointment City: </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Appointment City"
                          value={editbooking.appointment_city}
                          name="appointment_city"
                          onChange={handleEditInputChange3}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Appointment State: </Form.Label>
                        {/* <Form.Control
                      type="text"
                      placeholder="Enter Appointment State"
                      value={editbooking.appointment_state}
                      name="appointment_state"
                      onChange={handleEditInputChange3}
                    /> */}

                        <Form.Select aria-label="Default select example" className="custom-select-box" onChange={
                          (e) => { setEditbookingstate(e.target.value) }
                        } name="state">
                          {states.map((state) => (
                            <option value={state.stateCode} selected={editbooking.appointment_state == state.stateCode}>{state.name}</option>
                          ))}

                        </Form.Select>

                      </Form.Group>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Appointment Zip: </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Appointment Zip"
                          value={editbooking.appointment_zip}
                          name="appointment_zip"
                          onChange={handleEditInputChange3}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Assign Appointment to Staff</Form.Label>
                        {
                          selectedOptions && (
                            <Select
                              isMulti
                              defaultValue={selectedOptions}
                              options={staffList}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={(e) => {
                                setAssignStaff(e)
                              }}
                            />

                          )
                        }


                      </Form.Group>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Appointment Notes: </Form.Label>
                        <Form.Control
                          type="text"
                          as="textarea"
                          aria-label="With textarea"
                          value={editbooking.appointment_note}
                          name="appointment_note"
                          onChange={handleEditInputChange3}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Start Date: </Form.Label>
                        <Form.Control type="date" value={EditbookingStartDate || ""} onChange={
                          (e) => {
                            setEditbookingStartDate(e.target.value)
                          }
                        } />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Start Time: </Form.Label>
                        <Form.Control type="time" value={EditbookingStartTime || ""} onChange={
                          // (e) => {
                          //   setEditbookingStartTime(e.target.value)
                          // }
                          handleStartTimeChange
                        } />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>End Date: </Form.Label>
                        <Form.Control type="date" value={EditbookingStartDate || ""} onChange={
                          (e) => {
                            setEditbookingStartDate(e.target.value)
                          }
                        } />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>End Time: </Form.Label>
                        <Form.Control type="time" value={
                          // moment(editbooking.start_time, "HH:mm:ss").add(expedtedendtime, "minutes").format("HH:mm:ss")
                          // getCalculatedEndTime(editbooking.start_time, expedtedendtime)
                          editbookingtimeEnd
                        } />
                      </Form.Group>
                    </div>
                  </div>
                </form>
              </>
            )
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" style={{ display: loading ? 'none' : 'block' }} onClick={handleClose3}>
            Cancel
          </Button>
          <Button variant="primary" style={{ display: loading ? 'none' : 'block' }} onClick={UpdateBookigData}>
            Update
          </Button>
          {editbooking.recurring_id && editbooking.recurring_id !== null && editbooking.recurring_id !== "" ? (
            <Button className="primary btn-big-width" style={{ display: loading ? 'none' : 'block' }} onClick={
              () => {
                updaterecurringbooking(editbooking.recurring_id)
              }
            }>{apicall ? <Spinner animation="border" /> : "Update all recurring"} </Button>
          ) : ''}
        </Modal.Footer>
      </Modal>
      {/* EDIT BOOKING MODEL STOP HERE */}

      {/* reschedule appointment modal start  */}
      <Modal show={show5} onHide={handleClose5} className="Sidebar-Modal-end">
        <Modal.Header closeButton>
          <Modal.Title>Reschedule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Amount</Form.Label>
              <Form.Control type="text" value={rescheduleDetails.amount} placeholder="$128" disabled />
            </Form.Group>
            <div className="mb-3">
              <Select
                isMulti
                defaultValue={selectstaffs}
                options={staffList}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(e) => {
                  setReschedulestaff(e)
                }}
              />
            </div>


            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Date</Form.Label>
              <Form.Control type="date" value={rescheduleDate} name="rescheduleDate" onChange={(e) => {
                setRescheduleDate(e.target.value)
              }} />
            </Form.Group>

            <FloatingLabel
              controlId="floatingTextarea"
              label="Note"
              className="mb-3"
            >
              <Form.Control as="textarea" placeholder="Leave a comment here" value={reschedunotes} onChange={
                (e) => {
                  setReschedulenotes(e.target.value)
                }
              } name="app_notes" />
            </FloatingLabel>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose5}>
            Close
          </Button>
          <Button variant="primary" onClick={savereschedule}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      {/* reschedule appointment modal end  */}

      {/* edit appointment modal start */}
      <Modal show={show4} onHide={handleClose4} className="Sidebar-Modal-end">
        <Modal.Header closeButton>
          <Modal.Title>Card Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-8">
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Stax Id:</Form.Label>
              </Form.Group>
              <div className="">
                <div className="create-stax-id mb-3">
                  {
                    edituser.customer_id == "" ?
                      <button className="btn btn-primary actions-btn" onClick={createStaxId}>
                        Create Stax Id
                      </button>
                      :
                      <p>{edituser.customer_id}</p>

                  }
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="payment-settings-content-header create-stax-id-btn mb-3">
                <button href="#" class="btn Add-bttn ms-auto" onClick={handleShow6}>
                  <span>
                    <BsPlusLg />
                  </span>
                  Add Card
                </button>
              </div>
            </div>
          </div>
          <div
            className=""
            style={{
              display: edituser.customer_id === "" ? "none" : "block",
            }}
          >
            <div className="booking-details-deta-inner">
              <ul className="ct-cal-booking-details">
                <li className="ct-cal-booking-details-item">
                  <label>Price</label>
                  <span>: {`$` + invoiceprice}</span>
                </li>
                <li className="ct-cal-booking-details-item">
                  <label>Booking Discount Price</label>
                  <span>: {"$" + getCalculateddiscount(bookingPriceTest, bookingDiscount)}</span>
                </li>
                <li className="ct-cal-booking-details-item">
                  <label>Booking Discount</label>
                  <span>:{"$" + bookingDiscount}</span>
                </li>
                <li className="ct-cal-booking-details-item">
                  <label>Billing Notes</label>
                  <span>: {bookingNotes && bookingNotes ? bookingNotes : " "}</span>
                </li>
                <li className="ct-cal-booking-details-item">
                  <label>Notes</label>
                  <span>{invoicenotes === " "
                    ? " "
                    : getNotes(invoicenotes)}{" "}</span>
                </li>
                <li className="ct-cal-booking-details-item">
                  <label>Team Cleaning Time</label>
                  <span>: {cleaningtime}</span>
                </li>
                <li className="ct-cal-booking-details-item">
                  <label>Technician Time</label>
                  <span>: {gettechnicialtime(bookingtimesheetcleaningtime)}</span>
                </li>
                <li className="ct-cal-booking-details-item">
                  <label>Timesheet Notes</label>
                  <span>: {gettechtimesheetnotes(bookingTimeNote)}</span>
                </li>
              </ul>
            </div>

            <div className="payment-invoice mb-3">
              <div className="card">
                <div className="card-body">
                  <div className="card-title-invoice">
                    <h3>Invoice</h3>
                    <div className="add-invoice-btn">
                      <button className="btn-invoice" onClick={addRow}>< MdAdd />Add More</button>
                    </div>
                  </div>
                  <div className="invoice-list">
                    <table className="table table-invoice">
                      <thead>
                        <tr>
                          <th>DETAILS</th>
                          <th>QUANTITY</th>
                          <th>PRICE</th>
                          <th>AMOUNT</th>
                        </tr>
                      </thead>
                      <tbody>
                        {rows.map((row, index) => (
                          <tr key={index} className="mt-3">
                            <td style={{ width: '100%' }}>
                              <div className="my-1 flex-nowrap input-group">
                                <Form.Select
                                  // value={row.service}
                                  aria-label="Default select example"
                                  onChange={(e) => updateRow(index, 'service', e.target.value)}
                                >
                                  <option>Service Name</option>
                                  {productlist.map((item, itemIndex) => (
                                    <option key={itemIndex} value={`${item.id} ${item.item}`} selected={row.service === item.id}>
                                      {item.item}
                                    </option>
                                  ))}
                                </Form.Select>
                              </div>
                              <div className="my-1 flex-nowrap input-group">
                                <input
                                  name="notes"
                                  type="text"
                                  className="form-control"
                                  value={row.notes}
                                  onChange={(e) => updateRow(index, 'notes', e.target.value)}
                                />
                              </div>
                            </td>
                            <td style={{ width: '100%' }}>
                              <div className="my-1 flex-nowrap input-group">
                                <input
                                  name="quantity"
                                  type="text"
                                  className="form-control"
                                  value={row.quantity}
                                  onChange={(e) => {
                                    updateRow(index, 'quantity', e.target.value)
                                    const pricequantity = e.target.value;
                                    const total = parseFloat(pricequantity) * row.price;
                                    updateRow(index, 'total', total);
                                  }}
                                />
                              </div>
                            </td>
                            <td style={{ width: '100%' }}>
                              <div className="my-1 flex-nowrap input-group price-width">
                                <input
                                  name="Price"
                                  type="text"
                                  className="form-control"
                                  value={row.price}
                                  onChange={(e) => {
                                    const priceValue = e.target.value;
                                    updateRow(index, 'price', priceValue);
                                    const total = parseFloat(priceValue) * row.quantity;
                                    updateRow(index, 'total', total);
                                  }}
                                />
                              </div>
                            </td>
                            <td style={{ width: '100%' }}>
                              <b>{row.total ? `$${row.total}` : '$0'}</b>
                            </td>
                            {index === 0 ? (
                              <></>
                            ) : (
                              <button className="remove-row-btn" onClick={() => removeRow(index)}>
                                <MdClose />
                              </button>
                            )}
                          </tr>
                        ))}
                        <tr>

                        </tr>
                      </tbody>
                    </table>
                    <div className="d-flex justify-content-between align-items-center gap-4 mb-3">
                      <div className="discount">
                        <b>Discount : </b>
                      </div>
                      <div className="w-50">
                        <div style={{ width: '100%' }}>
                          <div className="my-1 flex-nowrap input-group">
                            <input
                              name="discount"
                              type="text"
                              className="form-control"
                              value={discountinvoice}
                              onChange={(e) => setDiscountinoice(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center gap-4 mb-3">
                      <div className="total">
                        <b>Total :</b>
                      </div>
                      <div className="w-50">
                        <div style={{ width: '100%' }}>
                          <div className="my-1 flex-nowrap input-group">
                            <input
                              name="discount"
                              type="text"
                              className="form-control"
                              value={`$${calculateTotal(rows, discountinvoice)}`}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="payment-settings-content-header d-flex justify-content-beetwin align-items-center mb-4">
              <button href="#" class="btn Add-bttn ms-auto" onClick={createinvoice}>
                <span>
                  <BsPlusLg />
                </span>
                Create Invoice
              </button>
            </div>
            <hr />
            <div className="row">
              <h3>Invoice List</h3>
              <div className="col-md-12 mt-4">
                <div className="animated fadeIn">
                  {
                    invoiceLoader ? <Spinner animation="grow" variant="success" /> : (
                      <div className="row">
                        <div className="col-md-12">
                          <div className="card">
                            <div className="card-header d-flex table-card-header">
                              <Form.Group>
                                <div className='d-flex'>
                                  <div addonType="prepend">
                                  </div>
                                  <Form.Control type="text" placeholder="Keywords..." onChange={handleChange} />
                                </div>
                              </Form.Group>
                            </div>
                            <div className="card-body">
                              <BootstrapTable bootstrap4 keyField='id' data={invoiceList} columns={invoicecolumns} pagination={pagination} />
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose4}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* edit appointment modal end */}

      {/* Shipping address modal start  */}
      <Modal show={show8} onHide={handleClose8} className="Sidebar-Modal-end">
        <Modal.Header closeButton>
          <Modal.Title>Shipping address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Address Title</Form.Label>
              <Form.Control type="text" value={addresstitle} name="addresstitle" onChange={(e) => { setAddresstitle(e.target.value) }} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Shipping Address</Form.Label>
              <Form.Control type="text" value={newshippingaddress} name="newshippingaddress" onChange={(e) => { setNewshippingaddress(e.target.value) }} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Shipping Zip Code</Form.Label>
              <Form.Control type="text" value={newshippingzip} name="newshippingzip" onChange={(e) => { setNewshippingzip(e.target.value) }} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Shipping City</Form.Label>
              <Form.Control type="text" value={newshippingcity} name="newshippingcity" onChange={(e) => { setNewshippingcity(e.target.value) }} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Shipping State</Form.Label>
              <Form.Control type="text" value={newshippingstate} name="newshippingstate" onChange={(e) => { setNewshippingstate(e.target.value) }} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose8}>
            Close
          </Button>
          <Button variant="primary" onClick={saveusershippingaddress}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Shipping address modal end  */}

      <Modal show={show9} onHide={handleClose9} centered className="modal-center">
        <Modal.Header closeButton>
          <Modal.Title>
            Filter data
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <label className="form-label">Search Technician</label>

            <div className="col-md-8">
              <div className="date-picker">
                <Form.Group
                  className="booking-custom-lable"
                  controlId="formBasicEmail"
                >
                  <div className="row">
                    {/* <div className="col-md-12">

                      <input
                        type="text"
                        className="form-control mb-3"
                        disabled
                        onClick={() => setShowDateDisplay(true)}
                        placeholder="Click to select dates"
                        value={
                          `${moment(sdate).format('DD-MM-YYYY')} - ${moment(edate).format('DD-MM-YYYY')}`
                        }
                      />
                      <DateRange
                        editableDateInputs={true}
                        onChange={(item) => {
                          setSdate(item.selection.startDate);
                          setEdate(item.selection.endDate);
                          setDates([item.selection]);
                        }}
                        shownDate={dates[0]?.startDate}
                        moveRangeOnFirstSelection={false}
                        ranges={dates}
                      />
                    </div> */}
                    <div className="col-md-12">
                      <DateRangePicker
                        onChange={(item) => {
                          setSdate(item.selection.startDate);
                          setEdate(item.selection.endDate);
                          setDates([item.selection]);
                        }}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        // months={2}
                        ranges={dates}
                        direction="horizontal"
                      />
                    </div>
                  </div>


                </Form.Group>
              </div>
            </div>
            {/* <div className="col-md-4">
            </div> */}
            <div className="date-picker">
              <Form.Group
                className="booking-custom-lable"
                controlId="formBasicEmail"
              >


                {
                  loadingsppiner ? (
                    <Button className="resize_btn btn" disabled>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </Button>
                  ) : (
                    <div className="toggle_btn">
                      <button className="resize_btn btn" onClick={
                        () => {
                          setBookingdata([]);
                          handleButtonClick();
                        }
                      }>
                        <div className="btn-text">
                          Submit
                        </div>
                      </button>
                    </div>
                  )
                }




              </Form.Group>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* EDIT USER MODEL START HERE */}
      <Modal show={show} onHide={() => { handleClose(); props.onCloseidChange(0); }} className="Sidebar-Modal-end full-width-modal">
        <Modal.Header closeButton>
          <Modal.Title>
            {edituser.first_name + " " + edituser.last_name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="custum-modal-body">
          <div className="row">
            <div className="col-md-8">
              <div className="display-flex-content">
                <div className="appoiment-name">
                  {/* <h4>Appointment</h4> */}
                </div>
                {/* <div className="toggle_btn">
                        <button className="resize_btn btn" onClick={toggleStyle}>
                          <div className="btn-text">
                            {tapname}
                          </div>
                        </button>
                      </div> */}
              </div>
            </div>

          </div>
          <div className="row">
            <div className={leftsection}>
              <div className="row">
                <div className="col-md-6">
                  <div class="toggle_btn"><button class="resize_btn btn" onClick={handleShow9}><div class="btn-text">Filter</div></button></div>
                </div>
                <div className="col-md-6" >
                  <div className="date-picker">
                    <Form.Group
                      className="booking-custom-lable"
                      controlId="formBasicEmail"
                    >
                      <div className="toggle_btn">

                        <Form.Control type="text" placeholder="Keywords..." onChange={(e) => {
                          setSearchOrderId(e.target.value);
                        }} />

                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="col-md-6" style={{ display: "none" }}>
                  <div className="date-picker">
                    <Form.Group
                      className="booking-custom-lable"
                      controlId="formBasicEmail"
                    >
                      <div className="toggle_btn">
                        <button className="resize_btn btn" onClick={toggleStyle}>
                          <div className="btn-text">
                            {tapname}
                          </div>
                        </button>
                      </div>
                    </Form.Group>
                  </div>
                </div>
              </div>
              <div className="card-table-view" style={{ display: tableView }}>
                <div className="animated fadeIn mt-4" >
                  <div className="card">
                    <div className="card-header d-flex table-card-header">
                      {/* <SearchBox onSearch={handleSearch} /> */}
                    </div>
                    <div className="card-body">
                      {
                        loading ? (
                          <div className="loading">
                            <Roller />
                          </div>
                        ) :
                          pageOption.totalSize > 0 ? (
                            <>
                              <BootstrapTable
                                bootstrap4 keyField='id'
                                data={bookingdata}
                                columns={columns2}
                                rowStyle={rowStyle}
                                remote
                                onTableChange={handleTableChange}
                                pagination={paginationFactory(pageOption)}
                                noDataIndication="No data available" />
                            </>
                          ) : (
                            <p>
                              <strong>{`No Appointment Assign got this user  ${moment(sdate).format('DD-MM-YYYY')} to ${moment(edate).format('DD-MM-YYYY')}`}</strong>
                            </p>
                          )
                      }
                    </div>
                  </div>
                </div >
              </div>
              <div className="card-view" style={{ display: cardView }}>
                <div className="scroll-tab mt-4">
                  {
                    bookingdata.length === 0 ? (

                      <div className="row">
                        <div className="col-md-12">
                          <div className="content-body-form">
                            <div className="form-group mb-3">
                              <label htmlFor="exampleFormControlInput1" className="form-label">
                                No Booking Appointments
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        {
                          pageOption.totalSize > 0 && (
                            bookingdata.map((item) => (
                              <>
                                <div className="row">
                                  <div className={`booking-appoinment-card ${item.status === 'A' ? 'booking-yellow' : item.status === 'C' ? 'booking-green' : ''}`}>
                                    <div className="booking-appoinment-card-header">
                                      <div className="booking-appoinment-card-header-left">
                                        <div className="booking-appoinment-card-header-left-title">
                                          <h5>Appointment</h5>
                                          <p>{item.booking_date_time}</p>
                                        </div>
                                      </div>
                                      <div className="booking-appoinment-card-header-right">
                                        <div className="booking-appoinment-card-header-btn">
                                          <div className="appointment-btn-item">
                                            <div className="appointment-btn-item-icon">
                                              <img src={Doller} alt="Doller" />
                                            </div>
                                            <div className="appointment-btn-item-text">
                                              <p>{item.amount}</p>
                                            </div>
                                          </div>
                                          <div className="appointment-btn-item" style={{ display: "none" }}>
                                            <div className="appointment-btn-item-icon">
                                              <img src={Pay} alt="Pay" />
                                            </div>
                                            <div className="appointment-btn-item-text">
                                              <p>Pay Stax</p>
                                            </div>
                                          </div>
                                          <div className="appointment-btn-item">
                                            <div className="appointment-btn-item-icon">
                                              <img src={Active} alt="Active" />
                                            </div>
                                            <div className="appointment-btn-item-text">
                                              <p> {getStatusofbookings(item.status, item.book_by)}</p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="booking-appoinment-card-body">
                                      <div className="row">
                                        <div className="col-md-7">
                                          <div className="booking-appoinment-plx">
                                          </div>
                                          <div className="booking-appoinment-plx-details">
                                            <div className="booking-appoinment-plx-details-item">
                                              <div className="booking-appoinment-plx-details-item-left">
                                                <div className="user-name" style={{
                                                  fontSize: "16px",
                                                  fontWeight: "600",
                                                }}>
                                                  {item.fullname}
                                                </div>
                                              </div>
                                              <div className="booking-appoinment-plx-details-item-right">
                                                <div className="user-name">
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-5">
                                          <div className="booking-appoinment-plx">
                                            <h5 className="booking-appoinment-plx-title">
                                              Job Details{" "}
                                            </h5>
                                          </div>
                                          <div className="booking-appoinment-plx-details">
                                            <div className="booking-appoinment-plx-details-item">
                                              <div className="booking-appoinment-plx-details-item-box">
                                                <div className="">
                                                  Price :{item.amount}
                                                </div>
                                                <div className="">
                                                  Cleaning Time :
                                                  {item.booking_date_time}
                                                </div>
                                                <div className="">
                                                  Duration :{" "}
                                                  {getHoursMinutes(
                                                    item.order_duration
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <hr />
                                      <div className="row">
                                        <div className="col-md-7">
                                          <div className="booking-appoinment-plx">
                                            <h5 className="booking-appoinment-plx-title">
                                              Booking Details{" "}
                                            </h5>
                                          </div>
                                          <div className="booking-appoinment-plx-details">
                                            <div className="booking-appoinment-plx-details-item">
                                              <div className="booking-appoinment-plx-details-item-box">
                                                <div className="">
                                                  Methods - {item.title}
                                                </div>
                                                <div className="">
                                                  Addons : {
                                                    item.addons && item.addons.map((addons) => {
                                                      return (
                                                        (addons.addon_service_name + "-" + addons.addons_service_qty + " , ")
                                                      )
                                                    }
                                                    )
                                                  }
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-5">
                                          <div className="booking-appoinment-plx">
                                          </div>
                                          <div className="booking-appoinment-plx-details">
                                            <div className="booking-appoinment-plx-details-item">
                                              <div className="booking-appoinment-plx-details-item-box">
                                                <div className="">
                                                  {
                                                    item.subservices && item.subservices.map((subservice) => {
                                                      return (
                                                        (subservice.units_title + " - " + subservice.method_unit_qty + ",")
                                                      )
                                                    }
                                                    )
                                                  }
                                                </div>
                                                <div className="">
                                                  Note : {edituser.notes}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="booking-appoinment-card-footer">
                                      <div className="booking-appoinment-btn-list">
                                        <div className="booking-appoinment-btn-item">
                                          {
                                            item.status == "A" && item.status != "" && (

                                              <button className="btn btn-primary bg-yellow-dark acc-custom-btn" onClick={() => {
                                                getEditbooking(item.order_id);
                                              }
                                              } >
                                                Edit
                                              </button>
                                            )
                                          }

                                        </div>
                                        {item.status == "CO" && item.status != "" && (
                                          <>
                                            <Button className="Delete-Active-bttn" onClick={
                                              () => {
                                                uncomplatebooking(item.order_id)
                                              }
                                            }>
                                              <FaTimesCircle /> Uncomplete
                                            </Button>

                                            {edituser.customer_id !== 0 && edituser.customer_id !== null && edituser.customer_id !== "" ? (
                                              <Button className="pay-Active-bttn" onClick={(e) => {
                                                getsubserviceaddons(item.order_id);
                                                getinvoice(item.order_id);
                                                setInvoiceprice(item.amount);
                                                setorderId(item.order_id);
                                              }}>
                                                <FaMoneyBillAlt /> Pay Now
                                              </Button>
                                            ) : (
                                              <Button className="pay-Active-bttn" onClick={createStaxId}>
                                                <FaMoneyBillAlt /> Create Stax Id
                                              </Button>
                                            )}
                                          </>
                                        )}
                                        {item.status == "C" && item.status != "" ? (

                                          <>
                                            <Button className="confirm-Active-bttn" onClick={
                                              () => { complatebooking(item.order_id) }
                                            }>
                                              <FaCheckCircle /> Complete
                                            </Button>

                                            <Button className="unconfirm-Active-bttn" onClick={() => unconfirmbooking(item.order_id)}>
                                              <FaRegTimesCircle /> Unconfirm
                                            </Button>

                                            <Button className="Delete-Active-bttn" onClick={() => deletebooking(item.order_id)}>
                                              <RiDeleteBin6Line /> Delete
                                            </Button>
                                          </>
                                        ) : (
                                          ""
                                        )
                                        }{item.status == "A" && item.status != "" && (
                                          <>
                                            <Button className="confirm-Active-bttn" onClick={() => confirmbooking(item.order_id)}>
                                              <FaCheck /> Confirm
                                            </Button>

                                            <Button className="Delete-Active-bttn" onClick={() => deletebooking(item.order_id)}>
                                              <RiDeleteBin6Line /> Delete
                                            </Button>

                                            {item.recurring_id != 0 && item.recurring_id != null && item.recurring_id != ""
                                              ? (
                                                <Button className="Delete-Active-bttn" onClick={() => deletereccuringbooking(item.recurring_id, item.order_date)}> <RiDeleteBin6Line /> Delete all recurring</Button>
                                              ) : (
                                                // <Button className="Delete-Active-bttn" onClick={deletereccuringbooking} disabled> <RiDeleteBin6Line /> Delete all recurring </Button>
                                                ""
                                              )}
                                          </>
                                        )}

                                        {item.status == "R" && item.status != "" && (
                                          <>
                                            <Button className="confirm-Active-bttn" onClick={() => activebooking(item.order_id)}>
                                              <FaToggleOn /> Active
                                            </Button>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ))
                          )
                        }
                        <div className="load-view" style={{ display: "none" }}>
                          <button className="load-more-btn resize_btn btn" onClick={getbookingdata}>Load More</button>
                        </div>
                      </>
                    )
                  }
                </div>
              </div>
            </div>
            <div className={rightsection}>
              <div className="d-flex align-items-center justify-content-between flex-wrap mb-4">
                <div className="toggle_btn">
                  <button className="resize_btn btn" onClick={manageView}>
                    <div className="btn-icon">
                      <img src={require('../../assets/images/resize_width_icon.png')} alt="resize_width_icon" />
                    </div>
                    <div className="btn-text">
                      Resize
                    </div>
                  </button>
                </div>
                <div className="toggle_btn">
                  {
                    edituser.customer_id === "" ? (
                      <button className="btn btn-primary actions-btn" onClick={createStaxId}>
                        Create Stax Id
                      </button>
                    ) : (
                      invoiceLoader ? (
                        <Spinner animation="grow" variant="success" />
                      ) : (
                        <p className="mb-0">Stax Id:{edituser.customer_id}</p>
                      )
                    )
                  }

                </div>
              </div>
              <Accordion defaultActiveKey="0">
                <Accordion.Item
                  eventKey="0"
                  className="custom-accordion-item"
                >
                  <Accordion.Header className="accordion-card-header">
                    About This Customer
                  </Accordion.Header>
                  <Accordion.Body className="accordion-card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="user-profile-details">
                          <div className="user-profile-details-inner">
                            <div className="user-profile-img">
                              {profileImagePreview && (
                                <img
                                  src={
                                    SITE_URL + "uploads/photo/" + profileImagePreview
                                  }
                                  alt="Profile Preview"
                                  className="profile-img"
                                />
                              )}
                            </div>
                            <div className="user-profile-name">
                              <h4>{edituser.first_name + " " + edituser.last_name}</h4>
                              <p>
                                {edituser.user_email}{" "}
                                <button className="copy-btn" onClick={() => copy(edituser.user_email)}>
                                  <FiCopy />
                                </button>
                              </p>
                              <p>
                                +1{edituser.phone}{" "}
                                <button className="copy-btn" onClick={() => copy(edituser.phone)}>
                                  <FiCopy />
                                </button>
                              </p>
                              <p>
                                {
                                  edituser.keap_id ? (
                                    // <Link to={`https://mm229.infusionsoft.com/app/searchResults/searchResult?searchResult=${edituser.keap_id}`} target="_blank"/>
                                    <a href={`https://mm229.infusionsoft.com/app/searchResults/searchResult?searchResult=${edituser.keap_id}`} target="_blank" rel="noopener noreferrer">Check keap Details {edituser.keap_id}</a>
                                  )
                                    : (
                                      "No keap available"
                                    )
                                }
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row custom-pt-20">
                      <div className="col-md-6">
                        <div className="content-body-form">
                          <div
                            className="form-group mb-3"
                            controlId="validationCustom013"
                          >
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              First Name
                            </label>
                            <input
                              required
                              type="text"
                              className="form-control"
                              value={edituser.first_name || ""}
                              name="first_name"
                              onChange={handleEditInputChange}
                              placeholder="First Name"
                            />
                            <input
                              type="hidden"
                              className="form-control"
                              value={edituser.id || ""}
                              name="id"
                              onChange={handleEditInputChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="content-body-form">
                          <div
                            className="form-group mb-3"
                            controlId="validationCustom014"
                          >
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Last Name
                            </label>
                            <input
                              required
                              type="text"
                              className="form-control"
                              value={edituser.last_name || ""}
                              name="last_name"
                              onChange={handleEditInputChange}
                              placeholder="Last Name"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="content-body-form">
                          <div className="form-group mb-3" controlId="validationCustom016">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              Email Address
                            </label>
                            <div className="input-group">
                              <input
                                readOnly={true}
                                type="email"
                                className="form-control"
                                value={edituser.user_email || ""}
                                onChange={handleEditInputChange}
                                name="user_email"
                                placeholder="Email Address"
                              />
                              <button className="input_edit_btn" onClick={togglePopupVisibility}>
                                <TbEdit />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      {isPopupVisible && (
                        <div className="popup_view">
                          <div className="edit-email-popup">
                            <div className="edit-email-popup-inner">
                              <div className="edit-email-popup-inner-header">
                                <h4>Edit Email</h4>
                              </div>
                              <div className="edit-email-popup-inner-body">
                                <div className="edit-email-popup-inner-body-form">
                                  <div className="form-group mb-3">
                                    <label
                                      htmlFor="exampleFormControlInput1"
                                      className="form-label"
                                    >
                                      Email Address
                                    </label>
                                    <input
                                      type="email"
                                      className="form-control"
                                      value={updateemail || ""}
                                      onChange={(e) => {
                                        setUpdateemail(e.target.value);
                                      }}
                                      name="user_email"
                                      placeholder="Email Address"
                                    />
                                    {
                                      showuniqueemailerror && (<p className="error" style={{ color: "red" }} >The user email must be unique.</p>)
                                    }
                                  </div>
                                </div>
                                <div className="edit-email-popup-inner-body-footer">
                                  <button className="btn Save_btn" onClick={updateemaill}>Save</button>
                                  <button className="btn cancel_btn" onClick={togglePopupVisibility}>Cancel</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="col-md-12">
                        <div className="content-body-form">
                          <div
                            className="form-group mb-3"
                            controlId="validationCustom015"
                          >
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Phone Number
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={edituser.phone || ""}
                              onChange={handleEditInputChange}
                              name="phone"
                              placeholder="Phone Number"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <Form.Group
                          className=""
                          controlId="validationCustom017"
                        >
                          <Form.Label>Password</Form.Label>
                          <input
                            readOnly={true}
                            type="password"
                            className="form-control"
                            value={edituser.user_pwd || ""}
                            onChange={handleEditInputChange}
                            name="user_pwd"
                            placeholder="Password"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-6 mb-3">
                        <Form.Group
                          className=""
                          controlId="validationCustom018"
                        >
                          <Form.Label>Customer Stage</Form.Label>
                          <select
                            className="form-control"
                            name="customer_stage"
                            onChange={handleEditInputChange}
                          >
                            <option value="">Select Customer Stage</option>
                            <option
                              value="New Lead"
                              selected={edituser.customer_stage == "New Lead"}
                            >
                              New Lead
                            </option>
                            <option
                              value="2nd Lead Contact Attempt"
                              selected={
                                edituser.customer_stage ==
                                "2nd Lead Contact Attempt"
                              }
                            >
                              2nd Lead Contact Attempt
                            </option>
                            <option
                              value="3nd Lead Contact Attempt"
                              selected={
                                edituser.customer_stage ==
                                "3nd Lead Contact Attempt"
                              }
                            >
                              3nd Lead Contact Attempt
                            </option>
                            <option
                              value="Uncontacted Lead 1st Month"
                              selected={
                                edituser.customer_stage ==
                                "Uncontacted Lead 1st Month"
                              }
                            >
                              Uncontacted Lead 1st Month
                            </option>
                            <option
                              value="Quoted Not Booked"
                              selected={
                                edituser.customer_stage == "Quoted Not Booked"
                              }
                            >
                              Quoted Not Booked
                            </option>
                            <option
                              value="Not Quoted Not Booked"
                              selected={
                                edituser.customer_stage ==
                                "Not Quoted Not Booked"
                              }
                            >
                              Not Quoted Not Booked
                            </option>
                            <option
                              value="Unconverted Lead Long Term"
                              selected={
                                edituser.customer_stage ==
                                "Unconverted Lead Long Term"
                              }
                            >
                              Unconverted Lead Long Term
                            </option>
                            <option
                              value="No Availability Wait List"
                              selected={
                                edituser.customer_stage ==
                                "No Availability Wait List"
                              }
                            >
                              No Availability Wait List
                            </option>
                            <option
                              value="Booked 1st"
                              selected={
                                edituser.customer_stage == "Booked 1st"
                              }
                            >
                              Booked 1st
                            </option>
                            <option
                              value="Booked 2nd"
                              selected={
                                edituser.customer_stage == "Booked 2nd"
                              }
                            >
                              Booked 2nd
                            </option>
                            <option
                              value="Booked 3rd"
                              selected={
                                edituser.customer_stage == "Booked 3rd"
                              }
                            >
                              Booked 3rd
                            </option>
                            <option
                              value="Maturing"
                              selected={edituser.customer_stage == "Maturing"}
                            >
                              Maturing
                            </option>
                            <option
                              value="Fanatic"
                              selected={edituser.customer_stage == "Fanatic"}
                            >
                              Fanatic
                            </option>
                            <option
                              value="Irregular"
                              selected={
                                edituser.customer_stage == "Irregular"
                              }
                            >
                              Irregular
                            </option>
                            <option
                              value="Innactive"
                              selected={
                                edituser.customer_stage == "Innactive"
                              }
                            >
                              Innactive
                            </option>
                          </select>
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <Form.Group
                          className=""
                          controlId="validationCustom017"
                        >
                          <Form.Label>SMS Status</Form.Label>
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            name="sms_status"
                            checked={edituser.sms_status === "E"}
                            onChange={handleEditInputChange}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-6 mb-3">
                        <Form.Group
                          className=""
                          controlId="validationCustom018"
                        >
                          <Form.Label>Email Status</Form.Label>
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            name="email_status"
                            checked={edituser.email_status === "E"}
                            onChange={handleEditInputChange}
                          />
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row" style={{ display: "none" }}>
                      <div className="col-md-6">
                        <Form.Group
                          className=""
                          controlId="validationCustom019"
                        >
                          <Form.Label>City</Form.Label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            value={edituser.city || ""}
                            onChange={handleEditInputChange}
                            name="city"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-6 mb-3">
                        <Form.Group
                          className=""
                          controlId="validationCustom020"
                        >
                          <Form.Label>State</Form.Label>
                          {/* <input
                              required
                              type="text"
                              className="form-control"
                              value={edituser.state || ""}
                              onChange={handleEditInputChange}
                              name="state"
                              placeholder=""
                            /> */}

                          <Form.Select
                            required
                            aria-label="Default select example"
                            className="custom-select-box"
                            onChange={(e) => {
                              console.log(e.target.value);
                              setState(e.target.value);
                              handleEditInputChange(e);
                            }}
                          >
                            {/* <option>Select State</option> */}

                            {states.map((state) => (
                              <option value={state.stateCode} selected={edituser.state == state.stateCode}>{state.name}</option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item
                  eventKey="1"
                  className="custom-accordion-item mt-2"
                >
                  <Accordion.Header>Billing Details</Accordion.Header>
                  <Accordion.Body>
                    <div className="row custom-pt-20">
                      <div className="col-md-6">
                        <div className="content-body-form">
                          <div
                            className="form-group mb-3"
                            controlId="validationCustom021"
                          >
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Billing Address
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={edituser.address || ""}
                              name="address"
                              onChange={handleEditInputChange}
                              placeholder="Self Street"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="content-body-form">
                          <div
                            className="form-group mb-3"
                            controlId="validationCustom022"
                          >
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Billing Zip Code
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="zip"
                              value={edituser.zip || ""}
                              onChange={handleEditInputChange}
                              placeholder="910257"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <Form.Group
                          className=""
                          controlId="validationCustom019"
                        >
                          <Form.Label>Billing City</Form.Label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            value={edituser.city || ""}
                            onChange={handleEditInputChange}
                            name="city"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-6 mb-3">
                        <Form.Group
                          className=""
                          controlId="validationCustom020"
                        >
                          <Form.Label> Billing State</Form.Label>

                          <Form.Select
                            required
                            aria-label="Default select example"
                            className="custom-select-box"
                            onChange={(e) => {
                              setState(e.target.value);
                              // handleEditInputChange(e);
                            }}
                          >

                            {states.map((state) => (
                              <option value={state.stateCode} selected={edituser.state == state.stateCode || edituser.state == state.name}>{state.name}</option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="content-body-form">
                          <div
                            className="form-group mb-3"
                            controlId="validationCustom025"
                          >
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Select Default Shipping Address
                            </label>
                            {/* <Select
                              className="w-100"
                              defaultValue={selectedOption}
                              onChange={(e) => {
                                getusershippingaddressdata(e);
                              }}
                              options={Array.isArray(usershippingaddress) ? usershippingaddress : []}
                              onInputChange={(e) => {
                                handleInputChange(e);
                              }}
                            // onMenuScrollToBottom={handleMenuScrollToBottom}
                            /> */}

                            <Typeahead
                              id="basic-typeahead-single"
                              labelKey="label"
                              onChange={(e) => {
                                getusershippingaddressdata(e);
                              }}
                              // options={Array.isArray(usershippingaddress) ? usershippingaddress : []}
                              options={Array.isArray(usershippingaddress) ? usershippingaddress : []}
                              placeholder="Select Default Shipping Address"
                              selected={selectedOption}
                            />

                          </div>
                        </div>
                      </div>

                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="content-body-form">
                          <div
                            className="form-group mb-3"
                            controlId="validationCustom026"
                          >
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Default Shipping Address
                            </label>
                            <input
                              required
                              type="text"
                              className="form-control"
                              value={edituser.shipping_address || ""}
                              onChange={handleEditInputChange}
                              name="shipping_address"
                              placeholder="Self Street"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="content-body-form">
                          <div
                            className="form-group mb-3"
                            controlId="validationCustom026"
                          >
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Shipping Zip Code
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={edituser.shipping_zip || ""}
                              onChange={handleEditInputChange}
                              name="shipping_zip"
                              placeholder="910257"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <Form.Group
                          className=""
                          controlId="validationCustom027"
                        >
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Shipping City
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={edituser.shipping_city || ""}
                            onChange={handleEditInputChange}
                            name="shipping_city"
                            placeholder="City"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-6 mb-3">
                        <Form.Group controlId="validationCustom020">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Shipping State
                          </label>

                          <Form.Select aria-label="Default select example" className="custom-select-box" onChange={(e) => {
                            setshippingstate(e.target.value);
                          }}>
                            {states.map((state) => (
                              <option value={state.stateCode} selected={edituser.shipping_state == state.stateCode || edituser.shipping_state == state.name}>{state.name}</option>
                            ))}
                          </Form.Select>

                        </Form.Group>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item
                  eventKey="2"
                  className="custom-accordion-item mt-2"
                >
                  <Accordion.Header>Booking Data</Accordion.Header>
                  <Accordion.Body>
                    <div className="row">
                      <div className="col-md-6">
                        <Form.Group
                          className=""
                          controlId="validationCustom029"
                        >
                          <Form.Label>Default Payment Method</Form.Label>
                          <Form.Select
                            required
                            aria-label="Default select example"
                            className="custom-select-box"
                          >
                            <option value="1">Fattmerchant</option>
                          </Form.Select>
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <div className="content-body-form">
                          <div
                            className="form-group mb-3"
                            controlId="validationCustom030"
                          >
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Billed Amount
                            </label>
                            <input
                              required
                              type="text"
                              className="form-control "
                              value={edituser.billed_amount || ""}
                              onChange={handleEditInputChange}
                              name="billed_amount"
                              placeholder="500"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <Form.Group
                          className=""
                          controlId="validationCustom031"
                        >
                          <Form.Label>Bathrooms</Form.Label>
                          <input
                            required
                            type="number"
                            className="form-control "
                            value={edituser.bathrooms || ""}
                            onChange={handleEditInputChange}
                            name="bathrooms"
                            placeholder="500"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-6 mb-3">
                        <Form.Group
                          className=""
                          controlId="validationCustom032"
                        >
                          <Form.Label>Bedrooms</Form.Label>
                          <input
                            required
                            type="text"
                            className="form-control "
                            value={edituser.bedrooms || ""}
                            name="bedrooms"
                            onChange={handleEditInputChange}
                            placeholder="500"
                          />
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6" style={{ display: "none" }}>
                        <Form.Group
                          className=""
                          controlId="validationCustom033"
                        >
                          <Form.Label>Zone</Form.Label>
                          <input
                            required
                            type="text"
                            className="form-control "
                            value={edituser.zone || ""}
                            name="zone"
                            onChange={handleEditInputChange}
                            placeholder="500"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-12">
                        <div className="content-body-form">
                          <div
                            className="form-group mb-3"
                            controlId="validationCustom034"
                          >
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Billed Hours (HH:mm)
                            </label>
                            <InputMask
                              mask="99:99"
                              maskChar=""
                              alwaysShowMask
                              name="billed_hours"
                              formatChars={{ '9': '[0-9]', ':': ':' }}
                              placeholder="HH:mm"
                              className="form-control"
                              value={convertToHHMM(edituser.billed_hours)}
                              onChange={handleInputChange55}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="content-body-form">
                          <div
                            className="form-group mb-3"
                            controlId="validationCustom035"
                          >
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Key Number
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="key_number"
                              value={edituser.key_number || ""}
                              onChange={handleEditInputChange}
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="content-body-form">
                          <div
                            className="form-group mb-3"
                            controlId="validationCustom036"
                          >
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Preferences
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="preferences"
                              value={edituser.preferences || ""}
                              onChange={handleEditInputChange}
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="content-body-form">
                          <div
                            className="form-group mb-3"
                            controlId="validationCustom037"
                          >
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Family Info
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={edituser.family_info || ""}
                              name="family_info"
                              onChange={handleEditInputChange}
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="content-body-form">
                          <div
                            className="form-group mb-3"
                            controlId="validationCustom038"
                          >
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Parking Access
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="parking_access"
                              value={edituser.parking_access || ""}
                              onChange={handleEditInputChange}
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="content-body-form">
                          <div
                            className="form-group mb-3"
                            controlId="validationCustom039"
                          >
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Preferred Technician
                            </label>

                            <select className="form-control" name="preferrend_technician" onChange={
                              (e) => {
                                handleEditInputChange(e);
                              }
                            }>
                              <option value="">Select Technician</option>
                              {preferredStaff && preferredStaff.map((data) => {
                                return (
                                  <option value={data.fullname} selected={edituser.preferrend_technician == data.fullname}>{data.fullname}</option>
                                )
                              })}
                            </select>

                            {/* <input
                              type="text"
                              className="form-control"
                              value={edituser.preferrend_technician || ""}
                              name="preferrend_technician"
                              onChange={handleEditInputChange}
                              placeholder=""
                            /> */}

                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="content-body-form">
                          <div
                            className="form-group mb-3"
                            controlId="validationCustom040"
                          >
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Client notes from tech
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={edituser.technician_notes || ""}
                              name="technician_notes"
                              onChange={handleEditInputChange}
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="content-body-form">
                          <div
                            className="form-group mb-3"
                            controlId="validationCustom041"
                          >
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Special instructions from the client
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="special_instructions"
                              value={edituser.special_instructions || ""}
                              onChange={handleEditInputChange}
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="content-body-form">
                          <div
                            className="form-group mb-3"
                            controlId="validationCustom042"
                          >
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Special instructions from the admin
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={
                                edituser.special_instructions_admin || ""
                              }
                              name="special_instructions_admin"
                              onChange={handleEditInputChange}
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="content-body-form">
                          <div
                            className="form-group mb-3"
                            controlId="validationCustom042"
                          >
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Billing Notes
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={
                                edituser.billing_notes || ""
                              }
                              name="billing_notes"
                              onChange={handleEditInputChange}
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="content-body-form">
                          <div
                            className="form-group mb-3"
                            controlId="validationCustom042"
                          >
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Discount
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={
                                edituser.discount || ""
                              }
                              name="discount"
                              onChange={handleEditInputChange}
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item
                  eventKey="3"
                  className="custom-accordion-item mt-3"
                >
                  <Accordion.Header>Service defaults</Accordion.Header>
                  <Accordion.Body>
                    <div className="row">
                      <div className="extra-service booking-warrper-20">
                        <div className="row">
                          {service && service.map((data) => {
                            return (
                              <>
                                <div className="col-md-12">
                                  <h3 className="Service-details-box-inner-title">
                                    Service: {data.title}
                                  </h3>
                                  <div className="row mb-3 bg-light-color">
                                    <h3 className="Service-details-box-inner-title2">
                                      SubService
                                    </h3>
                                    {data.subservices.map((subservicess, innerIndex) => {
                                      //const innerObject = Object.values(subservicess);
                                      return (
                                        <div className="col-md-12" key={innerIndex}>
                                          <div className="Service-details-box-inner extra-service mb-0" key={innerIndex}>
                                            <div className="left-side">
                                              <div className="d-flex align-items-center">
                                                <h3 className="Service-details-box-inner-title2">{subservicess.units_title}</h3>
                                              </div>
                                            </div>
                                            <div className="right-side">
                                              <div className="d-flex align-items-center">
                                                <div className="extra-service-icon">
                                                  <Button
                                                    href="#"
                                                    className="add-item-btn"
                                                    disabled={subservicess.quantity === 1}
                                                    onClick={(e) => {
                                                      // Assuming changeCounter2 is a function that handles quantity changes
                                                      changeCounter2("decrement", subservicess.id, data.id);
                                                    }}
                                                  >
                                                    -
                                                  </Button>
                                                </div>
                                                <div className="extra-service-value">{subservicess.quantity}</div>
                                                <div className="extra-service-icon">
                                                  <Button
                                                    href="#"
                                                    className="add-item-btn"
                                                    disabled={subservicess.quantity === 6}
                                                    onClick={(e) => {
                                                      // Assuming changeCounter2 is a function that handles quantity changes
                                                      changeCounter2("increment", subservicess.id, data.id);
                                                    }}
                                                  >
                                                    +
                                                  </Button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                  <div className="row mb-3 bg-light-color">
                                    <h3 className="Service-details-box-inner-title2">
                                      Addons Service
                                    </h3>
                                    {data.addons.map((value) => {
                                      return (
                                        <div className="col-md-12">
                                          <div className="Service-details-box-inner extra-service mb-0">
                                            <div className="left-side">
                                              <div className="d-flex align-items-center">
                                                <h3 className="Service-details-box-inner-title2">
                                                  {value.addon_service_name}
                                                </h3>
                                              </div>
                                            </div>
                                            <div className="right-side">
                                              <div className="d-flex align-items-center">
                                                <div className="extra-service-icon">
                                                  <Button
                                                    href="#"
                                                    className="add-item-btn"
                                                    disabled={value.quantity == 0 ? true : false}
                                                    onClick={(e) => {
                                                      changeAddon("decrement", value.id, data.id);
                                                    }
                                                    }
                                                  >
                                                    -
                                                  </Button>
                                                </div>
                                                <div className="extra-service-value">
                                                  {value.quantity}
                                                </div>
                                                <div className="extra-service-icon">
                                                  <Button
                                                    href="#"
                                                    className="add-item-btn"
                                                    disabled={value.quantity == 6 ? true : false}
                                                    onClick={(e) => {
                                                      changeAddon("increment", value.id, data.id);
                                                    }
                                                    }
                                                  >
                                                    +
                                                  </Button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                                <hr />
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="content-body-form">
                          <div className="form-group mb-3" controlId="validationCustom043">
                            <button className="Active-bttn btn" type="submit" onClick={updateservicepreference} > Update </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item
                  eventKey="4"
                  className="custom-accordion-item mt-3"
                >
                  <Accordion.Header>Shipping Address List</Accordion.Header>
                  <Accordion.Body>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="content-body-form">
                          <div className="text-right mb-3" controlId="validationCustom043" style={{ textAlign: "-webkit-right" }}>
                            <button className="resize_btn btn" onClick={handleShow8}>Add</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="extra-service booking-warrper-20">
                        <div className="row">
                          {usershippingaddress && usershippingaddress.map((data) => {
                            return (
                              <>
                                <div className="col-md-12">

                                  <ul>
                                    <li className="d-flex align-items-center gap-3">
                                      {data.label}
                                      <div>
                                        <button className="Delete-Active-bttn-2" onClick={() => deleteusershippingaddress(data.value)} > <RiDeleteBin6Line /> </button>
                                      </div>
                                    </li>
                                  </ul>

                                </div>
                                <hr />
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>

              </Accordion>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="delete-btn">
            <button className="Delete-Active-bttn btn" type="submit" onClick={(e) => { setAddClasss(true) }} >
              Delete
            </button>
            <div className={"confirm-delete-modal" + (addClasss ? " show" : "")}>
              <h3 class="popover-title">Really do you want to delete this user?</h3>
              <div className="popover-content">
                <div className="btn-list">
                  <div className="btn-delete">
                    <Button className="confirm-delete" onClick={handleDelete}>
                      Delete
                    </Button>
                  </div>
                  <div className="btn-cancel">
                    <Button className="cancel-delete" onClick={(e) => { setAddClasss(false) }}>
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
          >
            <Button
              className="Edit-Active-bttn btn"
              onClick={sendemailpassword}
            >
              Send Password
            </Button>
          </OverlayTrigger>
          <button className="Active-bttn btn" type="submit" onClick={updatedUser} >
            {" "}
            Update{" "}
          </button>
          <button className="Cancel-bttn btn" onClick={() => { handleClose(); props.onCloseidChange(0); }}>
            {" "}
            Cancel{" "}
          </button>
        </Modal.Footer>
      </Modal>

      {/* Add Card modal start here */}
      <Modal show={show6} className="Sidebar-Modal-end" onHide={handleClose6} >
        <Modal.Header closeButton>
          <Modal.Title>New Card Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className='custum-modal-body'>
          <div className='row'>
            <div className='col-md-12'>
              <Form.Group className="mb-4" controlId="validationCustom01">
                <Form.Label className='custom-lable'>Card Number</Form.Label>
                <Form.Control
                  required
                  type="number"
                  placeholder="*** *** **** 9856"
                  className='custom-input'
                  value={cardNumber}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 16)
                  }}
                  onChange={(e) => { setCardNumber(e.target.value); }} />
                {addCardValidate && cardNumber == '' ? <p style={{ color: 'red' }}>card number is required</p> : null}
              </Form.Group>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-6'>
              <Form.Group className="mb-4" controlId="validationCustom01">
                <Form.Label className='custom-lable'>Exp. Date</Form.Label>
                <Form.Control required type="Text" placeholder="MM/YY" className='custom-input'
                  value={cardExpDate}
                  {...getExpiryDateProps({ onChange: (e) => setCardExpDate(e.target.value) })} />
                {addCardValidate && cardExpDate == '' ? <p style={{ color: 'red' }}>please enter card exipry date</p> : ''}
              </Form.Group>
            </div>
            <div className='col-md-6'>
              <Form.Group className="mb-4" controlId="validationCustom01">
                <Form.Label className='custom-lable'>CVV</Form.Label>
                <Form.Control required type="number" placeholder="CVV" className='custom-input'
                  value={cardCVV}
                  onInput={
                    (e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3)
                    }
                  }
                  onChange={(e) => setCardCVV(e.target.value)} />
                {addCardValidate && cardCVV == '' ? <p style={{ color: 'red' }}>please enter cvv</p> : ''}
                {addCardValidate && cardCVV.length != 3 ? <p style={{ color: 'red' }}>cvv must have 3 digits</p> : ''}
              </Form.Group>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-12'>
              <Form.Group className="mb-4" controlId="validationCustom01">
                <Form.Label className='custom-lable'>Full Name</Form.Label>
                <Form.Control required type="text" placeholder="Full Name" className='custom-input'
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)} />
                {addCardValidate && fullName == '' ? <p style={{ color: 'red' }}>please enter full name</p> : ''}
              </Form.Group>
            </div>
            {/* <div className='col-md-6'>
              <Form.Group className="mb-4" controlId="validationCustom01">
                <Form.Label className='custom-lable'>Last Name</Form.Label>
                <Form.Control required type="text" placeholder="Full Name" className='custom-input'
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)} />
                {addCardValidate && fullName == '' ? <p style={{ color: 'red' }}>please enter full name</p> : ''}
              </Form.Group>
            </div> */}
          </div>

          <div className='row'>
            <div className='modal-btn justify-content-end'>
              <button href="#" className='btn g-btn me-3' type="submit" onClick={handleAddCard} >Save</button>
              <button href="#" className='btn btn-danger' onClick={handleClose6}>Cancel</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* modal End here */}

      {/* Payment Method modal End here */}
      <Modal show={showModal7} className="Sidebar-Modal-end" onHide={handleClose7}>
        <Modal.Header closeButton>
          <Modal.Title>Fattmerchant Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body className='custum-modal-body'>

          {paymentMethod && paymentMethod.length != 0 && paymentMethod.map((item, index) => {
            const cardOptionId = `cardOption${index}`;
            const isFirstCard = index === 0;
            const isChecked = selectedCard === cardOptionId || (isFirstCard && !selectedCard);
            return (
              <div class="form-check mb-3">
                <div className="d-flex align-items-center">
                  <input className="form-check-input me-3" type="radio" name="cardOption" id={`cardOption${index}`}
                    value={`cardOption${index}`} checked={isChecked} defaultChecked={index === 0} onChange={(e) => {
                      setSelectedCard(e.target.value);
                      setPaymentMethodId(item.id);
                    }
                    }
                  />
                  {/* <input class="form-check-input me-3" type="radio" name="flexRadioDefault" id="flexRadioDefault1" /> */}
                  <label class="form-check-label payment-card-details" for="flexRadioDefault1">
                    <div className="card payment-card-details">
                      <div className="card-body">
                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                          <div>
                            <div className="card-title h5">****-****-****-{
                              item.card_last_four || " "
                            }</div>
                            <p className="card-text">{
                              item.nickname || " "
                            }</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            );
          })}
          <div className='row'>
            <div className='modal-btn justify-content-end'>
              <button href="#" className='btn g-btn me-3' type="submit" onClick={payinvoice}>Save</button>
              <button href="#" className='btn btn-danger' onClick={handleClose7}>Cancel</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {
        bookingidcrm != 0 ? (
          <ChildComponent order_id={bookingidcrm} onCloseidChangebooking={(editbookingid) => setBookingIdCrm(editbookingid)} onCloseidChangeoldbookingid={(oldbookingid) => setOldBookingId(oldbookingid)} />
        ) : (
          <></>
        )

      }

      {/* Payment Method modal End here */}
      <NotificationContainer />
      {/* </>
        )
      } */}
    </>
  );
};

function convertToHHMM(value) {
  const start_time = value;
  const hours = Math.floor(start_time / 60);
  const minutes = start_time % 60;

  // Format hours and minutes as two-digit numbers
  const formattedHours = hours < 10 ? '0' + hours : hours.toString();
  const formattedMinutes = minutes < 10 ? '0' + minutes : minutes.toString();

  // Combine hours and minutes in the "00:00" format
  const formattedTime = formattedHours + ':' + formattedMinutes;
  return formattedTime;
}


function getCalculateddiscount(booking_price, booking_discount) {
  if (booking_price != 0 && booking_discount != 0) {
    const discount = parseInt(booking_price) - parseInt(booking_discount);
    return discount;
  } else {
    return "0.00";
  }
}

const gettechnicialtime = (booking_timesheet_cleaning_time) => {
  if (Array.isArray(booking_timesheet_cleaning_time) && booking_timesheet_cleaning_time.length > 0) {
    const formattedNotes = booking_timesheet_cleaning_time.map(item => `${item.fullname} notes: ${convertToHHMM(item.time_difference)}`);
    return formattedNotes.join(', ');
  }
}

const gettechtimesheetnotes = (bookingNotes) => {
  if (Array.isArray(bookingNotes) && bookingNotes.length > 0) {
    const formattedNotes = bookingNotes.map(item => `${item.fullname} notes: ${item.note}`);
    return formattedNotes.join(', ');
  }
}

const getNotes = (bookingnotes = "") => {
  if (bookingnotes === null) {
    return "";
  } else {
    const notes = bookingnotes.replace(/(?:\r\n|\r|\n)/g, "<br />");
    const notes1 = notes.replace(/(<([^>]+)>)/gi, "");
    return notes1;
  }
};

function convertToHM(value) {
  const start_time = value;
  const hours = Math.floor(start_time / 60);
  const minutes = start_time % 60;

  // Calculate the total time in hours with decimal representation for minutes
  const totalHours = hours + (minutes / 60);

  return totalHours;
}

export default Informasi;
