import React, { useEffect, useState } from "react";
import { useContext } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import Step1 from "./Step01";
import Step2 from "./Step02";
import Step3 from "./Step03";
import MultiStepProgressBar from "./MultiStepProgressBar";
import '../../assets/css/Booking.css'
import moment from "moment";
import Step4 from "./Step04";
import 'react-notifications/lib/notifications.css';
import { BookingContext } from "./BookingContext";
import { LoginContext } from "../../LoginContext";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { addbooking } from "../../Api/frontbooingApi";
import { SignUp ,getuserdetails} from "../../Api/frontbooingApi";
import { getsubservicebyservice } from "../../Api/frontbooingApi";
import { getaddon } from "../../Api/frontbooingApi";


const MasterForm = () => {

    const [currentStep, setCurrentStep] = useState(1);
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [address, setAddress] = useState("");
    const [usertype, setUsertype, chooseservice, setChooseservice, choosesubservice, setChoosesubservice, chooseaddon, setChooseaddon, choosetechnician, setChoosetechnician, choosedate, setChoosedate, choosetime, setChoosetime, userDetails, setUserDetails, paymentDetails, setPaymentDetails, Totalpayment, setTotalpayment, totalDuration, setTotalDuration, appointmentNotes, setAppointmentNotes, servicename, setServicename, endchoosetime, setEndchoosetime, confirmpassword, setConfirmpassword, signUprequest, setSignUprequest, expectedprice, setExpectedprice, expectedduration, setExpectedduration] = useContext(BookingContext);
    const { LoggedInData, login, logout } = useContext(LoginContext);
    const [billedhours, setBilledhours] = useState("0");

    const [userbilledhours, setUserbilledhours] = useState(0);
    const [userbilledamount, setUserbilledamount] = useState(0);
    const [userid, setUserid] = useState('');

    const [defaultsubservice, setdefaultsubservice] = useState([]);
    const [defaultaddons, setdefaultaddons] = useState([]);

    useEffect(() => {
        if (LoggedInData != null) {
            // setUserbilledhours(LoggedInData.billed_hours);
            // setUserbilledamount(LoggedInData.billed_amount);
            setUserid(LoggedInData.id);
        }
    }, [LoggedInData]);


    useEffect(() => {
        if (chooseservice === "1") {
            const formData = new FormData();
            // formData.append("user_id", LoggedInData.id);
            if (LoggedInData && LoggedInData.id) {
                formData.append("user_id", LoggedInData.id);
            } else {
                console.error("LoggedInData is null or does not have an id property");
            }
            getuserdetails(formData).then((res) => {
                if (res.code === 200) {
                    if (res.data.status === 1) {
                        const apiResponse = res.data;
                        setUserbilledhours(apiResponse.data.billed_hours);
                        setUserbilledamount(apiResponse.data.billed_amount);
                    }
                } else {
                    alert("Something went wrong");
                }
            });
        }
    }, [chooseservice]);


    useEffect(() => {
        if (chooseservice === 0) {
            setdefaultsubservice([]);
            setdefaultaddons([]);
        }

        getsubservice();
        getaddonsservice();
    }, [chooseservice]);

    const getsubservice = () => {
        // setLoading(true);
        const formData = new FormData();
        formData.append("service_id", chooseservice);
        formData.append("user_id", userid);
        getsubservicebyservice(formData).then((res) => {
            if (res.code === 200) {
                // setLoading(false);
                const apiResponse = res.data;
                // setSubService(apiResponse.data);
                setdefaultsubservice(apiResponse.data);
            } else {
                alert("Something went wrong");
            }
        });
    };

    const getaddonsservice = () => {
        const formData = new FormData();
        formData.append("service_id", chooseservice);
        formData.append("user_id", userid);
        getaddon(formData).then((res) => {
            if (res.code === 200) {
                if (res.data.status === "1") {
                    const apiResponse = res.data;
                    setdefaultaddons(apiResponse.data);
                }
                else {
                    setdefaultaddons([]);
                }
            } else {
                alert("Something went wrong");
            }
        });
    };

    const UserSignUp = (event) => {

        if (userDetails.user_email == "" || userDetails.password == "" || confirmpassword == "" || userDetails.first_name == "" || userDetails.last_name == "" || userDetails.phone == "" || userDetails.zip == "" || userDetails.address == "" || userDetails.state == "" || userDetails.city == "") {
            //      NotificationManager.error('Password and confirm password should be same', 'Close after 3000ms', 3000);
            setSignUprequest(signUprequest + 1);
            return false;
        }


        const formData = new FormData();
        formData.append("email", userDetails.user_email);
        formData.append("password", userDetails.password);
        formData.append("confirmpassword", confirmpassword);
        formData.append("first_name", userDetails.first_name);
        formData.append("last_name", userDetails.last_name);
        formData.append("phone", userDetails.phone);
        formData.append("zipcode", userDetails.zip);
        formData.append("address", userDetails.address);
        formData.append("state", userDetails.state);
        formData.append("city", userDetails.city);
        formData.append("user_type", "0");
        SignUp(formData).then((res) => {
            if (res.code === 200) {
                if (res.data.status == '1') {
                    const apiResponse = res.data;
                    login(apiResponse.data);
                }
                else {
                    //get error message 
                    if (res.data.message) {
                        var error = res.data.message.match(/<p>(.*?)<\/p>/g);
                        // var errorString = "";
                        // error.forEach(element => {
                        //     errorString += element.replace("<p>", "").replace("</p>", "") + "\n";
                        // });
                        // NotificationManager.error(errorString, 'Close after 3000ms', 3000);

                        setUserDetails({ ...userDetails, emailexist: true });



                    }
                }

            } else {
                alert("Something went wrong");
            }
        });

    }

    const handleChange = event => {
        const { name, value } = event.target;
        switch (name) {
            case "email":
                setEmail(value);
                break;
            case "username":
                setUsername(value);
                break;
            case "password":
                setPassword(value);
                break;
            case "address":
                setAddress(value);
                break;
            default:
                break;
        }
    };

    const nextStep = () => {
        setCurrentStep(prevStep => prevStep >= 3 ? 4 : prevStep + 1);
    };

    const prevStep = () => {
        setCurrentStep(prevStep => prevStep <= 1 ? 1 : prevStep - 1);
    };

    const previousButton = () => {
        if (currentStep !== 1) {
            return (
                <Button color="secondary float-left" className="wizard-btn" onClick={prevStep}>
                    Previous
                </Button>
            );
        }
        return null;
    };

    const nextButton = () => {
        if (currentStep < 4) {
            if (currentStep == 1) {
                if (chooseservice.length == 0) {

                    return (
                        <Button color="primary float-right" className="wizard-btn" disabled> Next </Button>
                    );
                }
                else {
                    return (
                        <Button color="primary float-right" className="wizard-btn" onClick={nextStep}>
                            Next
                        </Button>
                    );
                }
            }
            else if (currentStep == 2) {
                if (choosetechnician.length == 0 || choosedate.length == 0 || choosetime.length == 0) {
                    return (
                        <Button color="primary float-right" className="wizard-btn" disabled> Next </Button>
                    );
                }
                else {
                    return (
                        <Button color="primary float-right" className="wizard-btn" onClick={nextStep}>
                            Next
                        </Button>
                    );
                }
            }
            else if (currentStep == 3) {

                if (LoggedInData != null) {

                    return (
                        <Button color="primary float-right" className="wizard-btn" onClick={nextStep}>
                            Next
                        </Button>
                    );
                }
                else {
                    return (
                        <Button color="primary float-right" className="wizard-btn" onClick={UserSignUp} > Next </Button>
                    );
                }


            }

            else {
                return (
                    <Button color="primary float-right" className="wizard-btn" onClick={nextStep}>
                        Next
                    </Button>
                );
            }
        }
        return null;
    };

    console.log("paymentDetails",paymentDetails);

    const submitButton = () => {
        if (currentStep > 3) {
            if (paymentDetails !== undefined && (paymentDetails?.id == " " || paymentDetails.id == null)) {
                return <Button color="primary float-right" className="wizard-submit-btn" disabled>Submit</Button>;
            }
            else {
                return <Button color="primary float-right" className="wizard-submit-btn" onClick={AddBookingData}>Submit</Button>;
            }
        }
        return null;
    };

    // const submitButton = () => {
    //     //check for paymentDetails 
    //     const defaultPaymentDetails = paymentDetails || { id: "" };
        
    //     //logiv
    //     if (currentStep > 3) {
    //         if (defaultPaymentDetails.id.trim() === "") {
    //             return <Button color="primary float-right" className="wizard-submit-btn" disabled>Submit</Button>;
    //         } else {
    //             return <Button color="primary float-right" className="wizard-submit-btn" onClick={AddBookingData}>Submit</Button>;
    //         }
    //     }
    //     return null;
    // };
    

    const getTimeData = () => {
        const start_time = totalDuration;
        var hours = Math.floor(start_time / 60);
        var minutes = start_time % 60;
        var ampm = hours >= 12 ? "pm" : "am";
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        var time = "0" + hours + ":" + minutes;
        return time;
    }

    function convertToHHMM(value) {
        const start_time = value;
        const hours = Math.floor(start_time / 60);
        const minutes = start_time % 60;

        // Format hours and minutes as two-digit numbers
        const formattedHours = hours < 10 ? '0' + hours : hours.toString();
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes.toString();

        // Combine hours and minutes in the "00:00" format
        const formattedTime = formattedHours + ':' + formattedMinutes;
        return formattedTime;
    }

    const recurringbookingdate = null;
    const recurringtime = null;

    const AddBookingData = () => { 

        if(choosedate === ""){
            alert("Please select date to add appointment");
            return;
        }

        if(choosetime === ""){
            alert("Please select time to add appointment");
            return;
        }

        if(choosetechnician === ""){
            alert("Please select technician to add appointment");
            return;
        }

        const formData = new FormData();
        formData.append("user_id", LoggedInData.id);
        formData.append("type", usertype);
        formData.append("service_id", chooseservice);
        formData.append("book_by", 1);

        // const staffIds = [];
        // for (let i = 0; i < choosetechnician.length; i++) {
        //   staffIds.push(choosetechnician[i].value);
        // }
        formData.append("sub_service_id", JSON.stringify(choosesubservice));
        formData.append("staff_id", choosetechnician);
        formData.append("app_notes", appointmentNotes);
        // formData.append("amount", expectedprice);
        if (chooseservice === "1") {
            if ((parseInt(choosesubservice[0]?.quantity) === parseInt(defaultsubservice[0]?.quantity)) && (parseInt(choosesubservice[1]?.quantity) === parseInt(defaultsubservice[1]?.quantity)) && (parseInt(chooseaddon[0]?.quantity) === parseInt(defaultaddons[0]?.quantity))) {
                if (!userbilledamount || userbilledamount == 0) {
                    formData.append("amount", expectedprice);
                } else {
                    formData.append("amount", userbilledamount);
                }

            } else {
                formData.append("amount", expectedprice);
            }

        } else {
            formData.append("amount", expectedprice);
        }
        formData.append(
            "client_name",
            LoggedInData.first_name + " " + LoggedInData.last_name
        );
        formData.append("firstname", LoggedInData.first_name);
        formData.append("lastname", LoggedInData.last_name);
        formData.append("client_email", LoggedInData.user_email);
        formData.append("client_phone", LoggedInData.phone);
        formData.append("client_address", LoggedInData.address);
        formData.append("client_city", LoggedInData.city);
        formData.append("client_state", LoggedInData.state);
        formData.append("client_zip", LoggedInData.zip);
        formData.append("client_zone", LoggedInData.zip);
        // formData.append("billed_hours", convertToHHMM(expectedduration));
        if (chooseservice === "1") {
            if ((parseInt(choosesubservice[0]?.quantity) === parseInt(defaultsubservice[0]?.quantity)) && (parseInt(choosesubservice[1]?.quantity) === parseInt(defaultsubservice[1]?.quantity)) && (parseInt(chooseaddon[0]?.quantity) === parseInt(defaultaddons[0]?.quantity))) {
                if (!userbilledamount || userbilledamount == 0) {
                    formData.append("billed_hours", convertToHHMM(expectedduration));
                } else {
                    formData.append("billed_hours", convertToHHMM(userbilledhours));
                }

            } else {
                formData.append("billed_hours", convertToHHMM(expectedduration));
            }

        } else {
            formData.append("billed_hours", convertToHHMM(expectedduration));
        }
        formData.append("bedrooms", LoggedInData.bedrooms);
        formData.append("bathrooms", LoggedInData.bathrooms);
        formData.append("user_pwd", LoggedInData.user_pwd);
        formData.append("booking_discount", 0);
        formData.append("booking_notes", "");
        const booking_date_time = moment(choosetime).format("YYYY-MM-DD HH:mm:ss");
        const order_date = moment(choosetime).format("YYYY-MM-DD");
        formData.append("booking_date_time", booking_date_time);
        formData.append("order_date", order_date);
        formData.append("payment_date", order_date);
        formData.append("recurring_booking_date", recurringbookingdate);
        formData.append("recurring_time_to", recurringtime);
        formData.append("addons", JSON.stringify(chooseaddon));

        // if (chooseservice === "1") {
        //     if ((parseInt(choosesubservice[0]?.quantity) === parseInt(defaultsubservice[0]?.quantity)) && (parseInt(choosesubservice[1]?.quantity) === parseInt(defaultsubservice[1]?.quantity)) && (parseInt(chooseaddon[0]?.quantity) === parseInt(defaultaddons[0]?.quantity))) {
        //         if (!userbilledamount || userbilledamount === 0) {
        //             formData.append("estimated_duration", "$" + (userbilledamount) + " to $" + Math.round((userbilledamount)));
        //             formData.append("estimated_price", convertToHHMM(userbilledhours) + " to " + convertToHHMM(userbilledhours));
        //         } else {
        //             formData.append("estimated_duration", "$" + (Totalpayment) + " to $" + Math.round((expectedprice)));
        //             formData.append("estimated_price", convertToHHMM(totalDuration) + " to " + convertToHHMM(expectedduration));
        //         }

        //     } else {
        //         formData.append("estimated_duration", "$" + (Totalpayment) + " to $" + Math.round((expectedprice)));
        //         formData.append("estimated_price", convertToHHMM(totalDuration) + " to " + convertToHHMM(expectedduration));
        //     }

        // } else {
        //     formData.append("estimated_duration", "$" + (Totalpayment) + " to $" + Math.round((expectedprice)));
        //     formData.append("estimated_price", convertToHHMM(totalDuration) + " to " + convertToHHMM(expectedduration));
        // }

        if (chooseservice === "1") {
            if ((parseInt(choosesubservice[0]?.quantity) === parseInt(defaultsubservice[0]?.quantity)) && (parseInt(choosesubservice[1]?.quantity) === parseInt(defaultsubservice[1]?.quantity)) && (parseInt(chooseaddon[0]?.quantity) === parseInt(defaultaddons[0]?.quantity))) {
                if (!userbilledamount || userbilledamount == 0) {
                    formData.append("estimated_price", "$" + (Totalpayment) + " to $" + Math.round((expectedprice)));
                    formData.append("estimated_duration", convertToHHMM(totalDuration) + " to " + convertToHHMM(expectedduration));
                } else {
                    formData.append("estimated_price", "$" + (userbilledamount) + " to $" + Math.round((userbilledamount)));
                    formData.append("estimated_duration", convertToHHMM(userbilledhours) + " to " + convertToHHMM(userbilledhours));
                }
            } else {
                // formData.append("estimated_duration", "$" + (Totalpayment) + " to $" + Math.round((expectedprice)));
                // formData.append("estimated_price", convertToHHMM(totalDuration) + " to " + convertToHHMM(expectedduration));
                formData.append("estimated_price", "$" + (Totalpayment) + " to $" + Math.round((expectedprice)));
                formData.append("estimated_duration", convertToHHMM(totalDuration) + " to " + convertToHHMM(expectedduration));
            }
        } else {
            formData.append("estimated_price", "$" + (Totalpayment) + " to $" + Math.round((expectedprice)));
            formData.append("estimated_duration", convertToHHMM(totalDuration) + " to " + convertToHHMM(expectedduration));
        }
        

        if (recurringbookingdate !== "" && recurringtime !== "" && recurringbookingdate !== null && recurringtime !== null) {
            formData.append("recurring_status", "Y");
        } else {
            formData.append("recurring_status", "N");
        }

        if (paymentDetails != null) {
            formData.append("paymentDetails", JSON.stringify(paymentDetails));
            formData.append("customer_id", LoggedInData.customer_id);
        }

        addbooking(formData).then((res) => {
            if (res.code === 200) {
                if (res.data.status === "1") {
                    NotificationManager.info('Booking Added Successfully');
                    setUsertype(''); 
                    setChoosetechnician('');
                    setChoosedate('');
                    setChoosetime(''); 
                    window.location.href = "https://greenfrogcleaning.com/";
                } else {
                    alert("Booking Not Added");
                }
            } else {
                alert("Something went wrong");
            }
        });

    };



    return (
        <>
            {/* <Form> */}
            <div className="card booking-form-card">
                <div className="card-body">
                    <div className="card-title">
                        <MultiStepProgressBar currentStep={currentStep} />
                    </div>
                    <Step1
                        currentStep={currentStep}
                        handleChange={handleChange}
                        email={email}
                        availableTimestatus={false}
                    />
                    <Step2
                        currentStep={currentStep}
                        handleChange={handleChange}
                        email={username}
                    />
                    <Step3
                        currentStep={currentStep}
                        handleChange={handleChange}
                        email={password}
                    />
                    <Step4
                        currentStep={currentStep}
                        handleChange={handleChange}
                        email={address}
                    />
                </div>
                <div className="card-footer booking-card-btn-footer">
                    {previousButton()}
                    {nextButton()}
                    {submitButton()}
                </div>
            </div>
            <NotificationContainer />
            {/* </Form> */}
        </>
    );
}

export default MasterForm;
